const meta = {
  "/": {
    title: "Home | Castell Group",
    description:
      "Explore Castell Group's construction projects, client testimonials, and top clients. Contact us for expert main contracting and land development services.",
  },
  "/about-us": {
    title: "About Us | Castell Group",
    description: "Learn about Castell Group's values, mission, what we do, career opportunities, and meet the team!",
  },
  "/vacancies": {
    title: "Vacancies | Castell Group",
    description:
      "Join the Castell Group team! View our current job vacancies such as Quantity Surveying, Site Management, and Accountancy, and apply today.",
  },
  "/clients": {
    title: "Clients | Castell Group",
    description:
      "Discover Castell Group's clients. Contact us for expert main contracting and land development services. Learn about our constrcution practices.",
  },
  "/projects": {
    title: "Projects | Castell Group",
    description: "Explore Castell Group's completed and ongoing construction projects.",
  },
  "/blogs": {
    title: "Blogs | Castell Group",
    description:
      "See the latest news and updates from Castell Group. Read our blogs on construction, land development, and industry insights.",
  },
  "/landowners": {
    title: "Landowners | Castell Group",
    description:
      "Castell Group works with landowners to develop their land. Contact us to learn more about our land development services.",
  },
  "/contact-us": {
    title: "Contact Us | Castell Group",
    description:
      "Contact Castell Group for expert main contracting and land development services. Get in touch with our team today!",
  },
  "/free-resources": {
    title: "Free Resources | Castell Group",
    description:
      "Castell Group offers free resources for landowners and clients. Download our guides and resources on land development and construction.",
  },
  "/newsletter": {
    title: "Newsletter | Castell Group",
    description:
      "Subscribe to Castell Group's newsletter for the latest news and updates on construction and land development.",
  },
};

export { meta };
