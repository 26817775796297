import React, { useCallback, useRef } from "react";
import { projects_data } from "../utils/config/projects.js";
import { getRandomElements } from "../utils/general/misc.js";
import { insertTextbeforeUrlExtention } from "../utils/general/text-formatting.js";
import { res_our_portfolio_projects } from "../utils/config/image-resolutions.js";
import { handleMouseMove, handleMouseLeave } from "../utils/animations/tilt-animation-functions.js";
import { page_links } from "../utils/config/page-links.js";
import { ButtonWithLoadingBackground } from "./ImageLoaders.jsx";

const our_portfolio_projects = getRandomElements(
  Object.values(projects_data).filter((project) => project.main_cgi_url),
  6
);

export default function OurPortfolioProjects() {
  const buttonRefs = useRef([]);
  const throttledMouseMove = useRef(handleMouseMove(buttonRefs));
  // eslint-disable-next-line
  const handleMouseMoveCallback = useCallback(throttledMouseMove.current, []);
  // eslint-disable-next-line
  const handleMouseLeaveCallback = useCallback(handleMouseLeave(buttonRefs, throttledMouseMove.current), []);

  return (
    <div className="flex flex-col sm:flex-row w-full h-144 sm:h-128 bg-white px-[5%] py-8 gap-8">
      <div className="flex flex-col w-full sm:w-[25%] sm:h-full rounded-lg font-semibold justify-center items-center text-center sm:items-start sm:text-left gap-2 sm:gap-4">
        <div className="w-full text-lg sm:text-xl">OUR PORTFOLIO</div>
        <div className="w-full text-2xl sm:text-3xl">Creating Communities</div>
        <button
          aria-label="View all projects"
          className="w-[33%] sm:w-[66%] border border-black rounded-md mt-4 sm:mt-12 py-1 sm:py-2 text-lg sm:text-xl hover:bg-castell hover:text-white"
          onClick={() => {
            window.location.href = page_links["project"].link;
          }}
        >
          Projects
        </button>
      </div>
      <div className="grid grid-rows-6 grid-cols-1 lg:grid-rows-2 lg:grid-cols-3 w-full sm:w-[75%] h-full gap-4 text-white font-semibold text-xl">
        {our_portfolio_projects.slice(0, 6).map((project, index) => (
          <div
            key={index}
            ref={(el) => (buttonRefs.current[index] = el)}
            className="transition-transform duration-150"
            onMouseMove={(e) => handleMouseMoveCallback(e, index)}
            onMouseLeave={() => {
              handleMouseLeaveCallback(index);
            }}
          >
            <ButtonWithLoadingBackground
              onClick={() => {
                window.location.href = `${page_links["project"].link}/${project.id}`;
              }}
              className="relative w-full h-full bg-cover bg-center rounded-md overflow-hidden"
              imageUrl={insertTextbeforeUrlExtention(project.main_cgi_url, res_our_portfolio_projects)}
              aria_label={`View ${project.given_name || project.project_name}`}
            >
              <div className="absolute inset-0 bg-castell bg-opacity-40 backdrop-blur-extra-sm"></div>
              <span className="relative">{project.given_name || project.project_name}</span>
            </ButtonWithLoadingBackground>
          </div>
        ))}
      </div>
    </div>
  );
}
