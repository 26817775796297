import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowLeft } from "../utils/svg_components/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../utils/svg_components/arrow-right.svg";
import {
  handleScaleInlineTailwind300,
  handleEasInOut1000,
  handleAnimateZoomIn5000,
  handleFadeInUp1500Delay500,
} from "../utils/animations/animations.js";
import { insertTextbeforeUrlExtention } from "../utils/general/text-formatting.js";
import { res_home_page_carousel } from "../utils/config/image-resolutions.js";

export default function ImageCarousel({
  urls = [],
  text = [],
  buttons = true,
  animations = true,
  img_res = res_home_page_carousel,
  className = "relative w-full h-40 md:h-64 lg:h-96 xl:h-128 overflow-hidden flex items-center justify-center bg-white",
}) {
  const [currentUrlIndex, setCurrentUrlIndex] = useState(0);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(async () => {
      await handleNextClick();
    }, 5000);

    return () => clearInterval(interval);
  }, [currentUrlIndex]); // eslint-disable-line

  const handleNextClick = async () => {
    setCurrentUrlIndex((prevIndex) => (prevIndex + 1) % urls.length);
    setCurrentTextIndex((prevIndex) => (prevIndex + 1) % text.length);
  };

  const handlePrevClick = async () => {
    setCurrentUrlIndex((prevIndex) => (prevIndex - 1 + urls.length) % urls.length);
    setCurrentTextIndex((prevIndex) => (prevIndex - 1 + text.length) % text.length);
  };

  return (
    <div className={`${className}`}>
      <div
        className={`absolute inset-0 flex transition-transform duration-1000 ${animations && handleEasInOut1000}`}
        style={{ transform: `${animations && `translateX(-${currentUrlIndex * 100}%)`}` }}
      >
        {urls.map((url, index) => (
          <img
            key={index}
            // rel="preload"
            // loading="lazy"
            // as="image"
            src={insertTextbeforeUrlExtention(url, img_res)}
            alt={`Project ${index}`}
            className={`w-full h-full object-cover ${animations && handleAnimateZoomIn5000} ${
              index === currentUrlIndex && "z-10"
            }`}
          />
        ))}
      </div>
      <div className="absolute inset-0 bg-castell opacity-50 z-10" />

      <div className="absolute inset-0 flex items-center justify-center z-20">
        {text.length !== 0 && (
          <div className="flex w-[66%] flex-wrap text-center justify-center z-20 text-lg md:text-xl lg:text-2xl xl:text-3xl text-white">
            <span key={currentTextIndex} className={`${animations && handleFadeInUp1500Delay500}`}>
              {text[currentTextIndex]}
            </span>
          </div>
        )}
      </div>

      {buttons && urls.length > 1 && (
        <>
          <button
            aria-label="Previous Image"
            onClick={handlePrevClick}
            onMouseEnter={(e) => e.currentTarget.classList.add("scale-125")}
            onMouseLeave={(e) => e.currentTarget.classList.remove("scale-125")}
            className={`absolute left-0 top-1/2 transform -translate-y-1/2 focus:outline-none z-30 w-6 lg:w-8 ml-4 ${
              animations && handleScaleInlineTailwind300
            }`}
          >
            <ArrowLeft strokeWidth="1.5" className="h-full stroke-white" />
          </button>
          <button
            aria-label="Next Image"
            onClick={handleNextClick}
            onMouseEnter={(e) => e.currentTarget.classList.add("scale-125")}
            onMouseLeave={(e) => e.currentTarget.classList.remove("scale-125")}
            className={`absolute right-0 top-1/2 transform -translate-y-1/2 focus:outline-none z-30 w-6 lg:w-8 mr-4 ${
              animations && handleScaleInlineTailwind300
            }`}
          >
            <ArrowRight strokeWidth="1.5" className="h-full stroke-white" />
          </button>
        </>
      )}
    </div>
  );
}
