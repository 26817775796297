import { blog_data } from "../utils/config/blog";
import { useContext } from "react";
import { UserContext } from "../context_providers/UserContext";
import { getTextAfterLastSlash } from "../utils/general/text-formatting";
import { convertYyyymmddToWordyDate } from "../utils/general/date-conversions";
import {
  handle_bullet_points,
  handle_text_block,
  handle_image,
  handle_headings,
} from "../utils/functions/blog_functions.js";

export default function Blog() {
  const { location } = useContext(UserContext);
  const blogId = getTextAfterLastSlash(location.pathname);

  if (!blog_data[blogId]) {
    window.location.href = "/not-found";
  }

  const blog = blog_data[blogId];

  return (
    <div className="flex flex-row px-[10%] md:px-[20%] py-16 bg-white text-black gap-32 text-base lg:text-lg">
      {/* MAIN BLOG */}
      <div className="flex flex-col gap-8 w-full">
        <div className="flex flex-col gap-4">
          {blog.title && <div className="text-3xl lg:text-4xl font-semibold">{blog.title}</div>}
          {blog.date && <div className="text-lg lg:text-xl">{convertYyyymmddToWordyDate(blog.date)}</div>}
        </div>
        <div className="flex flex-col gap-8">
          {blog.sections.map((section, index) => (
            <div key={index} className="flex flex-col">
              {section.map((sub_section, index) => (
                <div key={index}>
                  {sub_section.headings
                    ? handle_headings(sub_section.headings)
                    : sub_section.image
                    ? handle_image(sub_section.image)
                    : sub_section.text_block
                    ? handle_text_block(sub_section.text_block)
                    : sub_section.bullet_points && handle_bullet_points(sub_section.bullet_points)}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
