import { image_url_prefix } from "./config.js";

const group_photo_urls = [
  `${image_url_prefix}4-1.png`,
  `${image_url_prefix}4-2.png`,
  `${image_url_prefix}4-3.png`,
  `${image_url_prefix}4-4.png`,
  `${image_url_prefix}4-5.png`,
  `${image_url_prefix}4-6.png`,
  `${image_url_prefix}4-7.png`,
];

const company_logo_urls = [
  `${image_url_prefix}1-castell-group-logo-squared-blue-transparent.png`,
  `${image_url_prefix}1-castell-logo-squared-blue-white.png`,
  `${image_url_prefix}1-castell-logo-squared-white-black.png`,
  `${image_url_prefix}1-castell-logo-squared-white-blue.png`,
  `${image_url_prefix}1-castell-logo-squared-whiteblue-transparent.png`,
  `${image_url_prefix}1-castell-logo-squared-white-transparent.png`,
  `${image_url_prefix}1-dark-logo-wide.png`,
  `${image_url_prefix}1-light-logo-wide.png`,
];

const b_roll_urls = [
  `${image_url_prefix}6-1.JPG`,
  `${image_url_prefix}6-2.jpg`,
  `${image_url_prefix}6-3.jpg`,
  `${image_url_prefix}6-4.jpg`,
  `${image_url_prefix}6-5.jpg`,
  `${image_url_prefix}6-6.jpg`,
  `${image_url_prefix}6-7.jpg`,
  `${image_url_prefix}6-8.jpg`,
  `${image_url_prefix}6-9.jpg`,
  `${image_url_prefix}6-10.jpg`,
  `${image_url_prefix}6-11.jpg`,
  `${image_url_prefix}6-12.jpg`,
  `${image_url_prefix}6-13.jpg`,
  `${image_url_prefix}6-14.jpg`,
  `${image_url_prefix}6-15.jpg`,
  `${image_url_prefix}6-16.png`,
  `${image_url_prefix}6-17.jpg`,
  `${image_url_prefix}6-18.jpg`,
  `${image_url_prefix}6-19.jpg`,
];

const abstract_image_urls = [
  `${image_url_prefix}99-abs-1.jpg`,
  `${image_url_prefix}99-abs-2.jpg`,
  `${image_url_prefix}99-abs-3.jpg`,
  `${image_url_prefix}99-abs-4.jpg`,
  `${image_url_prefix}99-abs-5.jpg`,
  `${image_url_prefix}99-abs-6.jpg`,
  `${image_url_prefix}99-abs-7.jpg`,
  `${image_url_prefix}99-abs-8.jpg`,
  `${image_url_prefix}99-abs-9.jpg`,
  `${image_url_prefix}99-abs-10.jpg`,
  `${image_url_prefix}99-abs-11.jpg`,
];

const vacancies_image_urls = [
  `${image_url_prefix}10-01.jpg`,
  `${image_url_prefix}10-02.jpg`,
  `${image_url_prefix}10-03.jpg`,
  `${image_url_prefix}10-04.jpg`,
  `${image_url_prefix}10-05.jpg`,
  `${image_url_prefix}10-06.jpg`,
  `${image_url_prefix}10-07.jpg`,
  `${image_url_prefix}10-08.jpg`,
  `${image_url_prefix}10-09.jpg`,
  `${image_url_prefix}10-10.jpg`,
  `${image_url_prefix}10-11.jpg`,
  `${image_url_prefix}10-12.jpg`,
  `${image_url_prefix}10-13.jpg`,
  `${image_url_prefix}10-14.jpg`,
];

const template_image_dark = company_logo_urls[6];
const template_image_light = company_logo_urls[7];
const header_bottom_logo = company_logo_urls[0];
const bullet_point_logo_white = company_logo_urls[5];
const bullet_point_logo_castell = company_logo_urls[1];
const about_us_background_image = group_photo_urls[2];
const abous_us_template_image = group_photo_urls[0];
const vacancies_background_image = group_photo_urls[6];

export {
  group_photo_urls,
  company_logo_urls,
  template_image_light,
  template_image_dark,
  header_bottom_logo,
  bullet_point_logo_white,
  bullet_point_logo_castell,
  about_us_background_image,
  abous_us_template_image,
  b_roll_urls,
  vacancies_background_image,
  abstract_image_urls,
  vacancies_image_urls,
};
