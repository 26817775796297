const google_analytics_tracking_id = "G-ZKSBFL1H77";

const items_tracked = [
  "Number of new and total users visiting this website",
  "Where users are coming to this website from (Organic, Direct, Referral, etc)",
  "Number of times this website and each page is viewed",
  "Average user engagement time and the engagement rate",
  "Number of times phone and email links are clicked",
  "The bounce rate",
];

const ga4_default_tracking = [
  "Device Type (Mobile, Desktop, Tablet, etc)",
  "Browser Type (Chrome, Safari, Firefox, etc)",
  "Screen Resolution (Width x Height)",
  "Language (English, Spanish, etc)",
  "Network Provider (BT, Vodafone, etc)",
  "Operating System (Windows, MacOS, Linux, etc)",
  "Geolocation (Removes the last octet of the IP, precision is reduced to city-level)",
  "Interactions (Scrolling, Clicks, File Downloads, etc)",
  "Embedded Video Engagement (Play, Pause, Time Watched, etc)",
];

const trackPage = (ReactGA, location) => {
  ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
};

const trackButtonClick = (ReactGA, category, label) => {
  ReactGA.event({ category, action: "button_click", label });
};

const trackLinkClick = (ReactGA, category, label) => {
  ReactGA.event({ category, action: "link_click", label });
};

export {
  google_analytics_tracking_id,
  items_tracked,
  ga4_default_tracking,
  trackPage,
  trackButtonClick,
  trackLinkClick,
};
