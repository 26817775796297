const res_types = ["", "_tinyres", "_lowres", "_midres", "_highres"];

const res_home_page_carousel = res_types[4];
const res_layout_background_image = res_types[4];
const res_our_portfolio_projects = res_types[1];
const res_map_popup_image = res_types[1];
const res_about_us_group_photo = res_types[3];
const res_about_us_staff_image = res_types[2];
const res_bullet_point_castell_logo = res_types[1];
const res_header_bottom_logo = res_types[2];
const res_housing_association_carousel = res_types[1];
const res_testimonial_image = res_types[2];
const res_free_resources = res_types[2];
const res_about_us_block_image = res_types[3];
const res_vacancies_background_image = res_types[4];
const res_b_roll_images = res_types[3];
const res_social_landlord_image = res_types[2];
const res_landowner_image = res_types[4];
const res_accreditation_carousel = res_types[1];

export {
  res_types,
  res_home_page_carousel,
  res_layout_background_image,
  res_our_portfolio_projects,
  res_map_popup_image,
  res_about_us_group_photo,
  res_about_us_staff_image,
  res_bullet_point_castell_logo,
  res_header_bottom_logo,
  res_housing_association_carousel,
  res_testimonial_image,
  res_free_resources,
  res_about_us_block_image,
  res_vacancies_background_image,
  res_b_roll_images,
  res_social_landlord_image,
  res_landowner_image,
  res_accreditation_carousel,
};
