import { projects_data } from "./projects.js";
import { getRandomElements } from "../general/misc.js";

const site_urls = Object.values(projects_data)
  .filter((project) => project.main_cgi_url !== "")
  .map((project) => project.main_cgi_url);

const random_site_urls = getRandomElements(site_urls, site_urls.length);
const layout_cover_image = random_site_urls[0];

const home_page_carousel = {
  urls: random_site_urls.slice(1, 6),
  text: ["We Build Sustainable Social Housing", "Beautiful Homes for Those in Need"],
};

export { site_urls, home_page_carousel, layout_cover_image, random_site_urls };
