import React, { useCallback, useContext, useRef } from "react";
import { insertTextbeforeUrlExtention } from "../utils/general/text-formatting.js";
import { res_types } from "../utils/config/image-resolutions.js";
import { handleMouseMove, handleMouseLeave } from "../utils/animations/tilt-animation-functions.js";
import { template_image_light } from "../utils/config/image-links.js";
import { blog_data } from "../utils/config/blog.js";
import { convertYyyymmddToWordyDate } from "../utils/general/date-conversions.js";
import { UserContext } from "../context_providers/UserContext.jsx";
import { page_links } from "../utils/config/page-links.js";
import { ImageLoader } from "../components/ImageLoaders.jsx";

export default function Blogs() {
  const { selectedBlogs } = useContext(UserContext);
  const buttonRefs = useRef([]);

  const throttledMouseMove = useRef(handleMouseMove(buttonRefs));
  // eslint-disable-next-line
  const handleMouseMoveCallback = useCallback(throttledMouseMove.current, []);
  // eslint-disable-next-line
  const handleMouseLeaveCallback = useCallback(handleMouseLeave(buttonRefs, throttledMouseMove.current), []);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 w-full px-[15%] py-12 gap-12 bg-white">
      {selectedBlogs
        .map((blogId) => blog_data[blogId])
        .map((blog, index) => (
          <button
            aria-label={`View ${blog.title}`}
            key={index}
            ref={(el) => (buttonRefs.current[index] = el)}
            onClick={() => (window.location.href = `${page_links["blog"].link}/${blog.id}`)}
            className="flex flex-col w-full rounded-lg overflow-hidden transition-transform duration-150 border border-gray-200 shadow-xl"
            onMouseMove={(e) => handleMouseMoveCallback(e, index)}
            onMouseLeave={() => handleMouseLeaveCallback(index)}
          >
            <ImageLoader
              src={
                blog.image.image_url !== ""
                  ? blog.image.image_url
                  : insertTextbeforeUrlExtention(template_image_light, res_types[1])
              }
              alt={blog.image.image_alt}
              containerClass="flex w-full h-64"
              imageClass={`w-full h-64 object-cover ${blog.image.top && "object-top"} ${
                blog.image.bottom && "object-bottom"
              }`}
            />
            <div className="flex flex-col w-full h-64 p-8 gap-4 text-left border-t border-gray-200">
              <div className="text-lg font-semibold">{blog.title}</div>
              <div className="text-sm text-gray-700">{blog.overview}</div>
            </div>
            <div className="flex w-full py-4 px-8 border-t text-sm border-gray-200 text-gray-500">
              {convertYyyymmddToWordyDate(blog.date)}
            </div>
          </button>
        ))}
    </div>
  );
}
