import HubspotForm from "../components/HubspotForm.jsx";
import { frm_newsletter } from "../utils/config/hubspot_forms.js";

export default function Newsletter() {
  return (
    <div className="flex flex-col w-full bg-white px-[20%] py-16 gap-8">
      <div className="flex flex-col gap-2 bg-white">
        <div className="text-3xl font-semibold">Sign Up for Our Newsletter!</div>
        <div className="text-xl">Stay up to date with our latest news and updates. We promise we won't spam you!</div>
      </div>
      <HubspotForm region={frm_newsletter.region} portalId={frm_newsletter.portalId} formId={frm_newsletter.formId} />
    </div>
  );
}
