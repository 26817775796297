import { image_url_prefix } from "./config.js";

const accreditations = {
  ccs: {
    id: "ccs",
    name: "Considerate Constructors Scheme",
    link: "https://www.ccscheme.org.uk/",
    img: { url: `${image_url_prefix}11-ccs.png`, alt: "Considerate Constructors Scheme" },
  },
  iso9001: {
    id: "iso9001",
    name: "ISO 9001",
    link: "https://www.iso.org/iso-9001-quality-management.html",
    img: { url: `${image_url_prefix}11-iso9001.png`, alt: "ISO 9001" },
  },
  chas: {
    id: "chas",
    name: "Contractors Health and Safety Assessment Scheme",
    link: "https://www.chas.co.uk/",
    img: { url: `${image_url_prefix}11-chas.png`, alt: "Contractors Health and Safety Assessment Scheme" },
  },
  iso14001: {
    id: "iso14001",
    name: "ISO 14001",
    link: "https://www.iso.org/iso-14001-environmental-management.html",
    img: { url: `${image_url_prefix}11-iso14001.png`, alt: "ISO 14001" },
  },
  ecologi: {
    id: "ecologi",
    name: "Ecologi",
    link: "https://ecologi.com/",
    img: { url: `${image_url_prefix}11-ecologi-2.png`, alt: "Ecologi" },
  },
  iso45001: {
    id: "iso45001",
    name: "ISO 45001",
    link: "https://www.iso.org/iso-45001-occupational-health-and-safety.html",
    img: { url: `${image_url_prefix}11-iso45001.png`, alt: "ISO 45001" },
  },
};

export { accreditations };
