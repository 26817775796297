// Constants for regex patterns
const MONTHS_SHORT = "(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)";
const YEAR_2DIGITS = "\\d{2}";
const YEAR_4DIGITS = "(19|20)\\d{2}";
const DAY = "(0[1-9]|[12][0-9]|3[01])";
const MONTH = "(0[1-9]|1[012])";

// Regex patterns for validation
const basicRegex = {
  letters: /^[a-zA-Z]*$/,
  numbers: /^[0-9]*$/,
  special: /^[!@#%&*()+=._-]+$/,
  email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$/,
};

const dateRegex = {
  date: /^\d{4}-\d{2}-\d{2}$/,
  dateMmmYy: new RegExp(`^(${MONTHS_SHORT})${YEAR_2DIGITS}$`, "i"),
  dateMmmSpaceYy: new RegExp(`^(${MONTHS_SHORT})\\s${YEAR_2DIGITS}$`, "i"),
  dateDdmmyyyy: new RegExp(`^${DAY}[- /.]${MONTH}[- /.]${YEAR_4DIGITS}$`),
  dateYyyymmdd: new RegExp(`^${YEAR_4DIGITS}[- /.]${MONTH}[- /.]${DAY}$`),
  dateExcel: /^\d{5}$/,
};

const riskRegex = {
  html: /<[^>]*>/,
  sql: /['"`\\(){}[\]#+\-=&|~^!?$;:,.<>]/,
  xss: /<(script|img|iframe|svg|canvas|audio|video|style|link|object|embed|applet|meta|base|frame|frameset|html|head|body|title|noscript|template|textarea|xmp|noembed|plaintext|button|input|select|option|optgroup|label|fieldset|legend|datalist|output|form|details|summary|menuitem|menu|dialog)>/,
};

const charRegex = {
  any64: /^.{1,64}$/,
  any128: /^.{1,128}$/,
  any256: /^.{1,256}$/,
};

const decisionRegex = {
  yesNo: /^(yes|no)$/i,
  trueFalse: /^(true|false)$/i,
};

const idRegex = {
  objectId: /^[0-9a-fA-F]{24}$/,
  uuid: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
};

const addressRegex = {
  addressLine: /^[a-zA-Z0-9\s\-,]+$/,
  postCode: /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i,
  countryName: /^[a-zA-Z\s]+$/,
  latitude: /^([-+]?([1-8]?\d(\.\d+)?|90(\.0+)?))$/,
  longitude: /^([-+]?((1[0-7]\d)|([1-9]?\d))(\.\d+)?|180(\.0+)?)$/,
  coordinates: /^([-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)),\s?([-+]?((1[0-7]\d)|([1-9]?\d))(\.\d+)?|180(\.0+)?)$/,
};

const castellRegex = {
  projectCode: /^C\d{3}$/,
};

export { basicRegex, dateRegex, riskRegex, charRegex, decisionRegex, idRegex, addressRegex, castellRegex };
