import { contacts } from "./contacts.js";
import { socials } from "./socials.js";
import { locations } from "./locations.js";
import { page_links } from "./page-links.js";
import { miscellanious } from "./miscellanious.js";
import { about_us } from "./text-blocks.js";

const header_top_socials = socials;
const header_top_contacts = [contacts[0], contacts[1], contacts[2]];
const header_bottom_links = page_links;
const footer_top_about_us = about_us;
const footer_top_contacts = [contacts[0], contacts[1], contacts[2]];
const footer_top_links = page_links;
const footer_top_socials = socials;
const footer_bottom = [locations[0], miscellanious[0]];
const image_url_prefix = "https://mvpblobcdn.blob.core.windows.net/blobimages/";

export {
  header_top_socials,
  header_top_contacts,
  footer_top_about_us,
  footer_top_contacts,
  footer_top_links,
  footer_top_socials,
  footer_bottom,
  header_bottom_links,
  image_url_prefix,
};
