import { ReactComponent as TwitterX } from "../svg_components/twitter-x.svg";
import { ReactComponent as YouTube } from "../svg_components/youtube.svg";
import { ReactComponent as LinkedIn } from "../svg_components/linkedin.svg";
import { ReactComponent as Facebook } from "../svg_components/facebook.svg";
import { ReactComponent as Instagram } from "../svg_components/instagram.svg";

const socials = [
  {
    id: "facebook",
    icon: Facebook,
    link: "https://web.facebook.com/CastellGroupUK/",
    ga4_event: {
      category: "Social",
      action: "social_link_click",
      label: "Facebook",
    },
  },
  {
    id: "linkedin",
    icon: LinkedIn,
    link: "https://www.linkedin.com/company/castellgroup",
    ga4_event: {
      category: "Social",
      action: "social_link_click",
      label: "LinkedIn",
    },
  },
  {
    id: "instagram",
    icon: Instagram,
    link: "https://www.instagram.com/castellgroup/",
    ga4_event: {
      category: "Social",
      action: "social_link_click",
      label: "Instagram",
    },
  },
  {
    id: "twitter",
    icon: TwitterX,
    link: "https://twitter.com/Castell_Group",
    ga4_event: {
      category: "Social",
      action: "social_link_click",
      label: "Twitter",
    },
  },
  {
    id: "youtube",
    icon: YouTube,
    link: "https://www.youtube.com/@castellgroup",
    ga4_event: {
      category: "Social",
      action: "social_link_click",
      label: "YouTube",
    },
  },
];

export { socials };
