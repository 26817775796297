import DepartmentsPeople from "../components/DepartmentsPeople.jsx";
import { insertTextbeforeUrlExtention } from "../utils/general/text-formatting.js";
import {
  about_us_background_image,
  bullet_point_logo_white,
  bullet_point_logo_castell,
  b_roll_urls,
} from "../utils/config/image-links.js";
import {
  res_about_us_group_photo,
  res_bullet_point_castell_logo,
  res_about_us_block_image,
} from "../utils/config/image-resolutions.js";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations.js";
import { our_core_values, our_mission, our_culture } from "../utils/config/text-blocks.js";
import { page_links } from "../utils/config/page-links.js";
import { DivWithLoadingBackground, ImageLoader } from "../components/ImageLoaders.jsx";
import AccreditationsCarousel from "../components/AccreditationsCarousel.jsx";

export default function AboutUs() {
  return (
    <div className="flex flex-col w-full bg-white">
      {/* BACKGROUND IMAGE */}
      <div className="flex w-full h-64 lg:h-96">
        <DivWithLoadingBackground
          className="relative w-full h-full bg-cover bg-center flex justify-center items-center"
          imageUrl={insertTextbeforeUrlExtention(about_us_background_image, res_about_us_group_photo)}
        >
          <div className="absolute inset-0 bg-castell bg-opacity-40" />
          <span className="relative text-white text-4xl font-semibold">About Us</span>
        </DivWithLoadingBackground>
      </div>
      <AccreditationsCarousel />

      {/* LAND LED DEVELOPMENT COMPANY */}
      <div className="flex flex-col xl:flex-row px-[10%]  bg-white text-castell items-center py-8 xl:py-16 gap-8 xl:gap-12">
        <ImageLoader
          src={insertTextbeforeUrlExtention(b_roll_urls[15], res_about_us_block_image)}
          alt={"Greenfield site in the countryside"}
          containerClass="flex w-full xl:w-[50%] h-full rounded-2xl overflow-hidden shadow-2xl border-2 border-castell border-opacity-25"
          imageClass="w-full h-48 md:h-64 lg:h-80 xl:h-96 object-cover"
        />
        <div className="flex flex-col xl:w-[50%] gap-4">
          <div className="text-2xl xl:text-3xl">We're a land-led development company</div>
          <div className="flex flex-col text-base xl:text-lg gap-4">
            <div>
              Our in-house teams manage the purchase of land as well as obtaining planning permission and entering into
              relevant agreements before we commence construction. The entire process of each development is overseen by
              us before the properties are handed over to the client.
            </div>
            <div>Our developments consist of predominately new build or conversions of brownfield sites.</div>
            <div>
              We are advocates of innovative and environmentally friendly construction techniques, including modern
              methods of construction (MMC) and offsite construction. Our next projects will be aiming to increase the
              use of innovative construction.
            </div>
          </div>
          <div className="flex w-full justify-center">
            <button
              aria_label="View our projects"
              className={`px-12 py-2 hover:bg-castell hover:text-white mt-4 font-semibold whitespace-nowrap text-left border border-castell rounded-sm ${handleScaleInlineTailwind300}`}
              onClick={() => (window.location.href = page_links["project"].link)}
              onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
              onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
            >
              View Our Projects
            </button>
          </div>
        </div>
      </div>

      {/* OUR CORE VALUES */}
      <div className="flex flex-col-reverse xl:flex-row px-[10%] bg-gradient-to-r from-castell to-castellpastel-800 text-white items-center py-8 xl:py-16 gap-8 xl:gap-12">
        <div className="flex flex-col xl:w-[50%] gap-4">
          <div className="text-2xl xl:text-3xl">Our Core Values</div>
          <div className="flex flex-col gap-4">
            {our_core_values.map((value, index) => (
              <div key={index} className="flex flex-row gap-4 text-base xl:text-lg  items-center">
                <img
                  src={insertTextbeforeUrlExtention(bullet_point_logo_white, res_bullet_point_castell_logo)}
                  alt={`logo`}
                  className={`w-8`}
                />
                {value}
              </div>
            ))}
          </div>
        </div>
        <ImageLoader
          src={insertTextbeforeUrlExtention(b_roll_urls[11], res_about_us_block_image)}
          alt={"Dorian and Anthony Flood on Frederick Place"}
          containerClass="flex w-full xl:w-[50%] h-full rounded-2xl overflow-hidden shadow-2xl border-2 border-white border-opacity-25"
          imageClass="w-full h-48 md:h-64 lg:h-80 xl:h-96 object-top object-cover"
        />
      </div>

      {/* OUR MISSION */}
      <div className="flex flex-col xl:flex-row px-[10%] items-center py-8 xl:py-16 gap-8 xl:gap-12">
        <ImageLoader
          src={insertTextbeforeUrlExtention(b_roll_urls[3], res_about_us_block_image)}
          alt={"Castell with Pobl on Neath Workingmens"}
          containerClass="flex w-full xl:w-[50%] h-full rounded-2xl overflow-hidden shadow-2xl border-2 border-white border-opacity-25"
          imageClass="w-full h-48 md:h-64 lg:h-80 xl:h-144 object-top object-cover"
        />
        <div className="flex flex-col xl:w-[50%] gap-4 text-base xl:text-lg">
          <div className="text-2xl xl:text-3xl">Our Mission</div>
          <div>
            We are going to to deliver at least 500 affordable, social and disabled homes per annum for those truly in
            need, in partnership with registered social landlords (RSLs) across South Wales.
          </div>
          <div>
            We will create a positive impact on all of the relevant parties in achieving this mission by doing the
            following:
          </div>
          <div className="flex flex-col gap-4">
            {our_mission.map((value, index) => (
              <div key={index} className="flex flex-row gap-4 items-center">
                <img
                  src={insertTextbeforeUrlExtention(bullet_point_logo_castell, res_bullet_point_castell_logo)}
                  alt={`logo`}
                  className={`w-8`}
                />
                {value}
              </div>
            ))}
          </div>
          <div className="flex w-full justify-center">
            <button
              aria_label="Contact us"
              className={`px-12 py-2 hover:bg-castell hover:text-white mt-4 font-semibold whitespace-nowrap text-left border border-castell rounded-sm ${handleScaleInlineTailwind300}`}
              onClick={() => (window.location.href = page_links["contact_us"].link)}
              onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
              onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>

      {/* CASTELL CAREERS */}
      <div className="flex flex-col-reverse xl:flex-row px-[10%] bg-gradient-to-r from-castell to-castellpastel-800 text-white items-center py-8 xl:py-16 gap-8 xl:gap-12">
        <div className="flex flex-col xl:w-[50%] gap-4">
          <div className="text-2xl xl:text-3xl">
            Castell Careers - We offer an exciting, fast paced and supportive working environment
          </div>
          <div className="text-lg xl:text-xl">
            Each person within the business represents the cornerstones of our culture:
          </div>

          <div className="flex flex-col gap-4">
            {our_culture.map((value, index) => (
              <div key={index} className="flex flex-row gap-4 text-base xl:text-lg  items-center">
                <img
                  src={insertTextbeforeUrlExtention(bullet_point_logo_white, res_bullet_point_castell_logo)}
                  alt={`logo`}
                  className={`w-8`}
                />
                {value}
              </div>
            ))}
          </div>
          <div className="flex w-full justify-center">
            <button
              aria_label="View Castell Vacancies"
              className={`px-12 py-2 hover:bg-white hover:text-castell mt-4 font-semibold whitespace-nowrap text-left border border-white rounded-sm ${handleScaleInlineTailwind300}`}
              onClick={() => (window.location.href = page_links["vacancy"].link)}
              onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
              onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
            >
              Castell Vacancies
            </button>
          </div>
        </div>
        <ImageLoader
          src={insertTextbeforeUrlExtention(b_roll_urls[16], res_about_us_block_image)}
          alt={"Bricklayer laying bricks"}
          containerClass="flex w-full xl:w-[50%] h-full rounded-2xl overflow-hidden shadow-2xl border-2 border-white border-opacity-25"
          imageClass="w-full h-48 md:h-64 lg:h-80 xl:h-96 object-cover"
        />
      </div>

      {/* COMPONENTS */}
      <DepartmentsPeople />
    </div>
  );
}
