import { image_url_prefix } from "./config.js";

const free_resources_data = {
  how_they_raised_over_9_million: {
    image: { image_url: `${image_url_prefix}8-1.png`, image_alt: "How to raise over £9 MILLION in investments" },
    title: "How to raise over £9 MILLION in investments",
    hubspot_form_link: "https://share-eu1.hsforms.com/1J6kDCEepSyeGs0QGO1hLVQfynva",
    active: true,
  },
};

export { free_resources_data };
