import { image_url_prefix } from "./config.js";

const testimonials = {
  stephan_yancek: {
    name: "Stephan Yancek",
    job_title: "Development Manager",
    company: "Caredig",
    testimonial: `"Caredig are pleased to be working in partnership with Castell Group to deliver 35 new homes."`,
    image_url: `${image_url_prefix}7-1.jpg`,
  },
  dorian_payne: {
    name: "Dorian Payne",
    job_title: "Managing Director",
    company: "Castell Group Ltd",
    testimonial: `"Castell are forecasted to be handing over 250 social homes per annum from 2025. All of which are EPC A-rated."`,
    image_url: `${image_url_prefix}7-2.jpg`,
  },
};

export { testimonials };
