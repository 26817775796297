import { ImageLoader } from "../../components/ImageLoaders.jsx";

const handle_bullet_points = (bullet_points) => {
  return (
    <ul className="flex flex-col list-disc ml-12 gap-2 mb-4">
      {bullet_points.map((point, index) => (
        <li key={index}>
          <strong>{point.header} </strong> {point.text}
        </li>
      ))}
    </ul>
  );
};

const handle_text_block = (text_block) => {
  return (
    <p className="mb-4">
      {text_block.map((text, itemIndex) => {
        if (text.link) {
          return (
            <a aria-label={text.aria_label} key={itemIndex} href={text.link} className="text-castell underline">
              {text.text}
            </a>
          );
        } else {
          return (
            <span key={itemIndex} className={`${text.bold && "font-semibold"} ${text.italic && "italic"}`}>
              {text.text}
            </span>
          );
        }
      })}
    </p>
  );
};

const handle_image = (image) => {
  return (
    <div className="flex flex-col w-full items-center justify-center py-8">
      <ImageLoader
        src={image.image_url}
        alt={image.image_alt}
        containerClass="flex"
        imageClass={`h-64 lg:h-128 object-cover mb-4 rounded-xl overflow-hidden shadow-xl`}
      />
      <span className="flex text-base italic font-semibold">{image.image_alt}</span>
    </div>
  );
};

const handle_headings = (headings) => {
  return (
    <div className="flex flex-col gap-2">
      {headings.map((heading, index) => (
        <div
          key={index}
          className={`font-semibold mb-2 ${heading.main ? "text-2xl lg:text-3xl" : "text-lg lg:text-xl"}`}
        >
          {heading.link ? (
            <a aria-label={heading.aria_label} href={heading.link} className="hover:text-castell">
              {heading.text}
            </a>
          ) : heading.main ? (
            <h1>{heading.text}</h1>
          ) : (
            <h2>{heading.text}</h2>
          )}
        </div>
      ))}
    </div>
  );
};

export { handle_bullet_points, handle_text_block, handle_image, handle_headings };
