const removeSpaces = (str) => {
  return str.replace(/\s/g, "");
};

const formatTitle = (title) => {
  title = title.trim();
  const words = title.split(/(\s|-)/);
  const capitalizedWords = words.map((word) => {
    if (word === " " || word === "-") return word;
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return capitalizedWords.join("");
};

const numberCommas = (value) => {
  // Check if the value is a number
  if (isNaN(value)) {
    console.warn("Attempting to convert to a number:", value);
    value = Number(value.replace(/[^0-9.-]+/g, "")); // Remove any non-numeric characters before conversion
    if (isNaN(value)) {
      console.error("Invalid value after conversion attempt");
      return "Invalid value"; // Return a descriptive error or you could choose to return the original value
    }
  }

  const formatter = new Intl.NumberFormat("en-GB");
  return formatter.format(value);
};

const currency = (value, currency) => {
  // Check if the value is a number
  if (isNaN(value)) {
    console.warn("Attempting to convert to a number:", value);
    value = Number(value.replace(/[^0-9.-]+/g, "")); // Remove any non-numeric characters before conversion
    if (isNaN(value)) {
      console.error("Invalid value after conversion attempt");
      return "Invalid value"; // Return a descriptive error or you could choose to return the original value
    }
  }

  // Define a map for currency options
  const currencyOptions = {
    GBP: { style: "currency", currency: "GBP" }, // British Pound
    USD: { style: "currency", currency: "USD" }, // US Dollar
    EUR: { style: "currency", currency: "EUR" }, // Euro
    JPY: { style: "currency", currency: "JPY" }, // Japanese Yen
    CNY: { style: "currency", currency: "CNY" }, // Chinese Yuan
    INR: { style: "currency", currency: "INR" }, // Indian Rupee
    AUD: { style: "currency", currency: "AUD" }, // Australian Dollar
  };

  if (!currencyOptions[currency]) {
    console.error("Unsupported currency");
    return "Unsupported currency";
  }

  const formatter = new Intl.NumberFormat("en-US", currencyOptions[currency]);
  return formatter.format(value);
};

const insertTextbeforeUrlExtention = (url, addition) => url.toString().replace(/(\.[^/.]+)$/, `${addition}$1`);

const getTextAfterLastSlash = (inputString) => inputString.split("/").pop();

export { removeSpaces, formatTitle, numberCommas, currency, insertTextbeforeUrlExtention, getTextAfterLastSlash };
