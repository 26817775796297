import { ReactComponent as ClosedMail } from "../svg_components/mail-closed.svg";
import { ReactComponent as Phone } from "../svg_components/phone.svg";

const contacts = [
  {
    id: "phone_number_1",
    icon: Phone,
    text: "0800 206 2308",
    link: "tel:0800%20206%202308",
    ga4_event: {
      category: "Contact",
      action: "contact_link_click",
      label: "0800 Number",
    },
  },
  {
    id: "phone_number_2",
    icon: Phone,
    text: "01792 910095",
    link: "tel:01792%20910095",
    ga4_event: {
      category: "Contact",
      action: "contact_link_click",
      label: "01792 Number",
    },
  },
  {
    id: "email_1",
    icon: ClosedMail,
    text: "hello@castellgroup.co.uk",
    link: "mailto:hello@castellgroup.co.uk",
    ga4_event: {
      category: "Contact",
      action: "contact_link_click",
      label: "hello@ Email",
    },
  },
];

export { contacts };
