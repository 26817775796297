import { insertTextbeforeUrlExtention } from "../utils/general/text-formatting.js";
import HousingAssociationCarousel from "../components/HousingAssociationCarousel.jsx";
import { b_roll_urls, company_logo_urls } from "../utils/config/image-links.js";
import { res_types, res_social_landlord_image } from "../utils/config/image-resolutions.js";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations.js";
import { our_services } from "../utils/config/our_services.js";
import React from "react";
import { page_links } from "../utils/config/page-links.js";
import { ImageLoader } from "../components/ImageLoaders.jsx";

export default function SocialLandlords() {
  return (
    <div className="flex flex-col w-full bg-white">
      <HousingAssociationCarousel />
      <div className="flex flex-col w-full px-[15%] py-8 1.5xl:py-16 gap-16 bg-white text-black">
        {/* SOCIAL LANDLORDS */}
        <div className="flex flex-col-reverse 1.5xl:flex-row gap-8 1.5xl:gap-16">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col font-semibold text-castell gap-4">
              <span className="text-3xl">Clients</span>
              <span className="text-xl">
                We collaborate with registered social landlords to deliver high quality homes and welcoming communities
              </span>
            </div>
            <div className="flex flex-col gap-6">
              <span>
                Castell Group partners with providers of social housing in South Wales to build affordable properties
                for those in need. We also specialise in the construction of disability adapted homes.
              </span>
              <span>
                Our homes are designed to achieve the highest energy efficiency rating (EPC A), saving tenants money on
                their fuel bills. Where possible, properties are fitted with electric heating rather than gas, and solar
                PV panels on the roof promote decarbonisation and decrease CO2 emissions.
              </span>
              <span>The size of our ideal scheme is 30 to 60 homes.</span>
            </div>
            <div className="flex justify-center 1.5xl:justify-start">
              <button
                aria_label="Contact us"
                className={`px-12 py-2 hover:bg-castell hover:text-white mt-4 font-semibold whitespace-nowrap text-left border border-castell rounded-sm ${handleScaleInlineTailwind300}`}
                onClick={() => (window.location.href = page_links["contact_us"].link)}
                onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
                onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
              >
                Contact Us
              </button>
            </div>
          </div>
          <ImageLoader
            src={insertTextbeforeUrlExtention(b_roll_urls[5], res_social_landlord_image)}
            alt="Castell Group meets with Caredig on Samlet Road"
            containerClass="flex w-full rounded-2xl overflow-hidden shadow-2xl border-2 border-castell border-opacity-25 h-48 1.5xl:h-112"
            imageClass="w-full object-cover"
          />
        </div>

        {/* OUR SERVICES */}
        <div className="relative flex flex-row w-full bg-black rounded-xl overflow-hidden">
          {/* IMAGE */}
          <div className="absolute inset-0 z-0">
            <img
              // src={insertTextbeforeUrlExtention(abstract_image_urls[4], res_types[4])}
              src={insertTextbeforeUrlExtention(company_logo_urls[2], res_types[3])}
              alt="background"
              className="w-full h-full object-cover opacity-15"
              style={{ objectPosition: "center" }} // This centers the image
            />
          </div>

          {/* BLOCKS IN A ROW */}
          <div className="hidden relative w-full 1.5xl:flex flex-row text-white">
            {our_services.map((service, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <div className="w-[5%] h-full bg-white" />}
                <div className="flex flex-col gap-6 p-8 w-[30%]">
                  <div className="text-2xl font-semibold">{service.title}</div>
                  <div className="flex flex-col gap-6">
                    {service.description.map((text, index) => (
                      <div key={index} className="">
                        {text}
                      </div>
                    ))}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>

          {/* 2 IN ROW ON TOP, ONE FULL WIDTH ON BOTTOM */}
          <div className="hidden 1.5xl:hidden relative w-full md:flex flex-col text-white">
            <div className="flex flex-row w-full">
              {[our_services[0], our_services[1]].map((service, index) => (
                <React.Fragment key={index}>
                  {index !== 0 && <div className="w-[4%] h-full bg-white" />}
                  <div className="flex flex-col gap-6 p-8 w-[48%]">
                    <div className="text-2xl font-semibold">{service.title}</div>
                    <div className="flex flex-col gap-6">
                      {service.description.map((text, index) => (
                        <div key={index} className="">
                          {text}
                        </div>
                      ))}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className="flex flex-col w-full">
              {[our_services[2]].map((service, index) => (
                <React.Fragment key={index}>
                  <div className="w-full aspect-[25/1] bg-white" />
                  <div className="flex flex-col gap-6 p-8 w-full">
                    <div className="text-2xl font-semibold">{service.title}</div>
                    <div className="flex flex-col gap-6">
                      {service.description.map((text, index) => (
                        <div key={index} className="">
                          {text}
                        </div>
                      ))}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>

          {/* BLOCKS IN A COLUMN */}
          <div className="md:hidden relative w-full flex flex-col text-white">
            {our_services.map((service, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <div className="w-full h-8 bg-white" />}
                <div className="flex flex-col gap-6 p-8 w-full">
                  <div className="text-2xl font-semibold">{service.title}</div>
                  <div className="flex flex-col gap-6">
                    {service.description.map((text, index) => (
                      <div key={index} className="">
                        {text}
                      </div>
                    ))}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
