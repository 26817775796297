import { useContext } from "react";
import { UserContext } from "../context_providers/UserContext.jsx";
import { projects_data } from "../utils/config/projects.js";
import { blog_data } from "../utils/config/blog.js";
import { vacancies_data } from "../utils/config/vacancies.js";

export default function DynamicLink({ link, bullet = false }) {
  // Destructure the link prop
  const { location } = useContext(UserContext);

  let dynamicText = link.text;

  if (link.id === "project" && location.pathname.split("/")[2]) {
    const project = projects_data[location.pathname.split("/")[2]];
    if (project) {
      dynamicText += ` > ${project.given_name || project.project_name}`;
    }
  } else if (link.id === "blog" && location.pathname.split("/")[2]) {
    const blog = blog_data[location.pathname.split("/")[2]];
    if (blog) {
      dynamicText += ` > ${blog.title}`;
    }
  } else if (link.id === "vacancy" && location.pathname.split("/")[2]) {
    const vacancy = vacancies_data[location.pathname.split("/")[2]];
    if (vacancy) {
      dynamicText += ` > ${vacancy.title}`;
    }
  }

  return (
    <div className="whitespace-nowrap overflow-hidden text-ellipsis">{`${
      bullet ? `${String.fromCharCode(160, 160)}•` : ""
    }${String.fromCharCode(160, 160)}${dynamicText}`}</div>
  );
}
