import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
  map_config,
  icon_styles,
  icon_template,
  popup_template,
  project_map_zoom,
  AutoOpenMarker,
} from "../utils/config/map-data.js";
import { office_data } from "../utils/config/offices.js";
import { template_image_dark } from "../utils/config/image-links.js";
import { res_map_popup_image } from "../utils/config/image-resolutions.js";

export default function OfficeMap() {
  return (
    <div className="flex flex-grow bg-white justify-center">
      <div className="w-full h-full bg-white">
        <MapContainer
          center={[office_data[0].address.longitude, office_data[0].address.latitude]}
          zoom={project_map_zoom}
          maxBounds={map_config.maxBounds}
          maxBoundsViscosity={map_config.maxBoundsViscosity}
          minZoom={map_config.minZoom}
          className="h-full w-full rounded-lg overflow-hidden border border-black"
        >
          <TileLayer url={map_config.tileLayerUrl} attribution={map_config.tileLayerAttribution} />
          {office_data.map((office) => (
            <AutoOpenMarker
              key={office.id}
              data_object={office}
              icon={icon_template(icon_styles["castell"])}
              popupContent={popup_template(
                office,
                template_image_dark,
                res_map_popup_image,
                "photo",
                office.google_maps_url
              )}
            />
          ))}
        </MapContainer>
      </div>
    </div>
  );
}
