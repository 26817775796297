import { useEffect, useState } from "react";
import Loader from "./Loader";

export default function HubspotForm({ region, portalId, formId, target = "#hubspotForm" }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js-eu1.hsforms.net/forms/embed/v2.js";
    script.type = "text/javascript";
    script.async = true;

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region,
          portalId,
          formId,
          target,
        });
        setLoading(false);
      } else {
        console.error("hbspt is not defined");
      }
    };

    script.onerror = () => {
      console.error("Failed to load the HubSpot script");
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [region, portalId, formId, target]);

  return <>{loading ? <Loader /> : <div id="hubspotForm" className="flex w-full h-full bg-white" />}</>;
}
