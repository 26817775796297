import React, { useContext, useState } from "react";
import { ReactComponent as RightArrow } from "../utils/svg_components/arrow-right.svg";
import { UserContext } from "../context_providers/UserContext.jsx";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations.js";
import { page_links } from "../utils/config/page-links.js";

export default function VacancySidebar() {
  const { location, vacancyDepartments, selectedVacancyDepartment, setSelectedVacancyDepartment } =
    useContext(UserContext);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      {location.pathname === page_links["vacancy"].link && (
        <div
          className={`lg:hidden fixed top-0 left-0 h-full flex items-center transform transition-transform duration-500 z-50 ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="flex flex-col w-64 h-full bg-white border-r border-gray-200 shadow-xl z-10">
            <div className="flex text-2xl font-semibold px-8 py-4 bg-castell text-white">Filter Vacancies</div>
            <div className="flex flex-col w-full h-full overflow-auto py-4">
              {vacancyDepartments.map((department, index) => (
                <button
                  aria-label={`Filter vacancies by ${department.name}`}
                  key={index} // Added a key for each button
                  className={`flex justify-start py-1 px-8 ${
                    selectedVacancyDepartment.id === department.id ? "bg-gray-200" : "hover:bg-gray-100"
                  }`}
                  onClick={() => {
                    setSelectedVacancyDepartment(department);
                  }}
                >
                  {department.name}
                </button>
              ))}
            </div>
          </div>

          <button
            aria-label={isSidebarOpen ? "Close sidebar" : "Open sidebar"}
            onClick={toggleSidebar}
            onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
            onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
            className={`${handleScaleInlineTailwind300} absolute right-0.5 top-1/2 transform -translate-y-1/2 translate-x-full w-14 border-r border-y border-gray-200 shadow-xl rounded-r-full bg-white p-2 z-0`}
          >
            <RightArrow
              strokeWidth="1.2"
              className={`w-full stroke-castell fill-white transition-transform duration-1000 ${
                isSidebarOpen ? "rotate-180" : "rotate-0"
              }`}
            />
          </button>
        </div>
      )}
    </React.Fragment>
  );
}
