import { useContext } from "react";
import HubspotForm from "../components/HubspotForm.jsx";
import OfficeMap from "../components/OfficeMap.jsx";
import { frm_contact_us } from "../utils/config/hubspot_forms.js";
import { UserContext } from "../context_providers/UserContext.jsx";
import { header_top_contacts } from "../utils/config/config.js";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations.js";
import ReactGA from "react-ga4";

export default function ContactUs() {
  const { location } = useContext(UserContext);

  return (
    <div className="flex flex-col w-full bg-white">
      <div className="flex flex-col 2xl:flex-row px-[10%] py-12 justify-center gap-8 bg-white 2xl:h-288">
        {location.pathname === "/contact-us" && (
          <div className="lg:hidden flex w-full justify-center border border-black py-6 rounded-lg">
            <div className={`flex flex-col gap-4 items-start text-castell font-semibold`}>
              {header_top_contacts.map((item) => (
                <a
                  aria_label={`Contact Castell on ${item.text}`}
                  key={item.id}
                  href={item.link}
                  onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
                  onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
                  className={`flex items-center gap-2 ${handleScaleInlineTailwind300}`}
                  onclick={() =>
                    ReactGA.event({
                      category: item.ga4_event.category,
                      action: item.ga4_event.action,
                      label: item.ga4_event.label,
                    })
                  }
                >
                  <item.icon strokeWidth="1.2" className="w-6 stroke-castell fill-white" />
                  <span>{item.text}</span>
                </a>
              ))}
            </div>
          </div>
        )}

        <div className="flex w-full h-96 2xl:w-[40%] 2xl:h-full z-10">
          <OfficeMap />
        </div>

        <div className="flex flex-col w-full 2xl:w-[60%] 2xl:h-full border border-black p-8 rounded-lg gap-8">
          <div className="flex flex-col gap-2">
            <div className="text-3xl font-semibold">Get in Touch</div>
            <div className="text-xl">We'd be delighted to answer any questions you may have.</div>
          </div>
          <HubspotForm
            region={frm_contact_us.region}
            portalId={frm_contact_us.portalId}
            formId={frm_contact_us.formId}
          />
        </div>
      </div>
    </div>
  );
}
