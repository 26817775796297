import { useContext } from "react";
import { UserContext } from "../context_providers/UserContext";
import { getTextAfterLastSlash, insertTextbeforeUrlExtention } from "../utils/general/text-formatting";
import { vacancies_data } from "../utils/config/vacancies";
import { template_image_light } from "../utils/config/image-links";
import HubspotForm from "../components/HubspotForm";
import { frm_vacancies } from "../utils/config/hubspot_forms";
import { ImageLoader } from "../components/ImageLoaders";

const handle_bullet_points = (bullet_points) => {
  return (
    <ul className="flex flex-col list-disc ml-12 gap-2">
      {bullet_points.map((point, index) => (
        <li key={index}>
          <strong>{point.header}</strong> {point.text}
        </li>
      ))}
    </ul>
  );
};

export default function Vacancy() {
  const { location } = useContext(UserContext);
  const vacancyId = getTextAfterLastSlash(location.pathname);

  if (!vacancies_data[vacancyId]) {
    window.location.href = "/not-found";
  }

  const vacancy = vacancies_data[vacancyId];

  return (
    <div className="flex flex-col px-[10%] md:px-[20%] py-16 bg-white text-black gap-16 text-base lg:text-lg">
      <div className="text-3xl lg:text-4xl font-semibold">{vacancy.title}</div>
      <div className="flex flex-col w-full items-center justify-center gap-8">
        <ImageLoader
          src={insertTextbeforeUrlExtention(vacancy.image.image_url || template_image_light, "_midres")}
          alt={vacancy.image.image_alt}
          containerClass="flex"
          imageClass={`h-64 lg:h-128 object-cover rounded-xl overflow-hidden shadow-xl`}
        />
        <div>{vacancy.role_overview}</div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-2xl font-semibold">Role Responsibiliities</div>
        {handle_bullet_points(vacancy.responsibilities)}
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-2xl font-semibold">Skills</div>
        {handle_bullet_points(vacancy.skills)}
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-2xl font-semibold">Qualifications</div>
        {handle_bullet_points(vacancy.qualifications)}
      </div>
      <p>
        <span>If you feel you would be a good fit, please fill out the form below, or send your CV to</span>{" "}
        <a
          aria-label={`Send your CV to ${vacancy.return_email}`}
          href={`mailto:${vacancy.return_email}`}
          className="text-castell underline"
        >
          {vacancy.return_email}
        </a>
      </p>
      <HubspotForm region={frm_vacancies.region} portalId={frm_vacancies.portalId} formId={frm_vacancies.formId} />
    </div>
  );
}
