import { image_url_prefix } from "./config.js";

const housing_associations = {
  caredig: {
    id: "caredig",
    name: "Caredig",
    link: "https://www.caredig.co.uk/",
    main_logo_url: `${image_url_prefix}5-cdg-1.png`,
    other_logo_urls: [`${image_url_prefix}5-cdg-1.png`],
  },
  tai_tarian: {
    id: "tai_tarian",
    name: "Tai Tarian",
    link: "https://www.taitarian.co.uk/",
    main_logo_url: `${image_url_prefix}5-ttn-1.png`,
    other_logo_urls: [`${image_url_prefix}5-ttn-1.png`],
  },
  hafod: {
    id: "hafod",
    name: "Hafod",
    link: "https://www.hafod.org.uk/",
    main_logo_url: `${image_url_prefix}5-hfd-1.jpg`,
    other_logo_urls: [`${image_url_prefix}5-hfd-1.jpg`],
  },
  linc: {
    id: "linc",
    name: "Linc Cymru",
    link: "https://www.linc-cymru.co.uk/",
    main_logo_url: `${image_url_prefix}5-lnc-1.jpg`,
    other_logo_urls: [`${image_url_prefix}5-lnc-1.jpg`],
  },
  newport_city_homes: {
    id: "newport_city_homes",
    name: "Newport City Homes",
    link: "https://www.newportcityhomes.com/",
    main_logo_url: `${image_url_prefix}5-nch-1.jpg`,
    other_logo_urls: [`${image_url_prefix}5-nch-1.jpg`],
  },
  newydd: {
    id: "newydd",
    name: "Newydd",
    link: "https://www.newydd.co.uk/",
    main_logo_url: `${image_url_prefix}5-nwd-1.png`,
    other_logo_urls: [`${image_url_prefix}5-nwd-1.png`],
  },
  valleys_2_coast: {
    id: "valleys_2_coast",
    name: "Valleys 2 Coast",
    link: "https://www.v2c.org.uk/",
    main_logo_url: `${image_url_prefix}5-v2c-1.png`,
    other_logo_urls: [`${image_url_prefix}5-v2c-1.png`],
  },
  pobl: {
    id: "pobl",
    name: "Pobl",
    link: "https://www.poblgroup.co.uk/",
    main_logo_url: `${image_url_prefix}5-pbl-1.png`,
    other_logo_urls: [`${image_url_prefix}5-pbl-1.png`],
  },
};

export { housing_associations };
