import React, { useState, useEffect } from "react";
import Loader from "./Loader";

const ImageLoader = ({ src, alt = "loading image", containerClass = "", imageClass = "" }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setIsLoading(false);
      setImageSrc(img.src);
    };
  }, [src]);

  return (
    <div className={`relative ${containerClass}`}>
      {isLoading && <Loader />}
      <img
        src={imageSrc}
        rel="preload"
        loading="lazy"
        as="image"
        alt={alt}
        className={`${isLoading ? "hidden" : "block"} ${imageClass}`}
      />
    </div>
  );
};

const ButtonWithLoadingBackground = ({
  onClick,
  style = {},
  className = "",
  children,
  imageUrl,
  aria_label,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedImageUrl, setLoadedImageUrl] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setIsLoading(false);
      setLoadedImageUrl(imageUrl);
    };
  }, [imageUrl]);

  return (
    <button
      aria-label={aria_label}
      onClick={onClick}
      className={`relative ${className}`}
      style={{
        ...style,
        backgroundImage: isLoading ? "none" : `url(${loadedImageUrl})`,
      }}
      {...props}
    >
      {isLoading && <Loader />}
      <div className={` ${isLoading ? "hidden" : "block"}`}>{children}</div>
    </button>
  );
};

const DivWithLoadingBackground = ({ style = {}, className = "", children, imageUrl, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedImageUrl, setLoadedImageUrl] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setIsLoading(false);
      setLoadedImageUrl(imageUrl);
    };
  }, [imageUrl]);

  return (
    <div
      className={`relative ${className}`}
      style={{
        ...style,
        backgroundImage: isLoading ? "none" : `url(${loadedImageUrl})`,
      }}
      {...props}
    >
      {isLoading && <Loader />}
      <div className={` ${isLoading ? "hidden" : "block"}`}>{children}</div>
    </div>
  );
};

export { ImageLoader, ButtonWithLoadingBackground, DivWithLoadingBackground };
