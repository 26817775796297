import { ReactComponent as Location } from "../svg_components/location.svg";

const locations = [
  {
    id: "main-office",
    icon: Location,
    text: "Castell Group, Unit 5, Dyffryn Court, Riverside Business Park, Swansea Vale, Swansea, Wales, SA7 0AP",
    link: "https://www.google.com/maps/place/51%C2%B040'10.6%22N+3%C2%B054'53.9%22W/@51.6696186,-3.9156076,183m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d51.6696178!4d-3.9149639?entry=ttu",
  },
];

export { locations };
