const frm_newsletter = {
  region: "eu1",
  portalId: "26811478",
  formId: "d1386bff-3cf7-4730-883f-d76dc8f2fdc6",
};

const frm_contact_us = {
  region: "eu1",
  portalId: "26811478",
  formId: "230f4d8b-fcb6-4282-8589-4231f62ed0d8",
};

const frm_vacancies = {
  region: "eu1",
  portalId: "26811478",
  formId: "ea41b9d5-fe2e-448d-bd36-c13c30e43ad8",
};

const frm_landowner = {
  region: "eu1",
  portalId: "26811478",
  formId: "7d1a89d0-47d4-4284-ad3e-14d2d870ea9b",
};

export { frm_newsletter, frm_contact_us, frm_vacancies, frm_landowner };
