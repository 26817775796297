import React, { useContext, useEffect, useMemo, useState } from "react";
import { blog_data } from "../utils/config/blog.js";
import { ReactComponent as RightArrow } from "../utils/svg_components/arrow-right.svg";
import { UserContext } from "../context_providers/UserContext.jsx";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations.js";
import { page_links } from "../utils/config/page-links.js";

const all_months = (blog_data_sorted) => {
  const first_date = new Date(blog_data_sorted[blog_data_sorted.length - 1].date);
  const last_date = new Date(blog_data_sorted[0].date);
  const monthsArray = [];
  let currentDate = new Date(first_date.getFullYear(), first_date.getMonth(), 1);

  // Iterate through each month until the last month
  while (currentDate <= last_date) {
    // Get the month and year
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString("default", { month: "long" });

    // Find post IDs that fall within the current month
    const postIdsInMonth = blog_data_sorted
      .filter((post) => {
        const postDate = new Date(post.date);
        return postDate.getFullYear() === year && postDate.getMonth() === currentDate.getMonth();
      })
      .map((post) => post.id); // Extract only the post ID

    // Add an object to the monthsArray with the current date and the post IDs in that month
    monthsArray.push({
      date: `${year} - ${month}`,
      posts: postIdsInMonth, // Store only the post IDs
    });

    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  return monthsArray.reverse();
};

export default function BlogSidebar() {
  const sorted_and_filtered_blog_data = useMemo(() => {
    return Object.values(blog_data)
      .filter((blog) => blog.active)
      .sort((b, a) => new Date(a.date) - new Date(b.date));
  }, []);
  const { location, setSelectedBlogs } = useContext(UserContext);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("All Blogs");

  useEffect(() => {
    setSelectedBlogs(sorted_and_filtered_blog_data.map((post) => post.id));
  }, [sorted_and_filtered_blog_data, setSelectedBlogs]);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      {location.pathname === page_links["blog"].link && (
        <div
          className={`fixed top-0 left-0 h-full flex items-center transform transition-transform duration-500 z-50 ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="flex flex-col w-64 h-full bg-white border-r border-gray-200 shadow-xl z-10">
            <div className="flex text-2xl font-semibold px-8 py-4 bg-castell text-white">Filter Blogs</div>
            <div className="flex flex-col w-full h-full overflow-auto py-4">
              {[
                { date: "All Blogs", posts: sorted_and_filtered_blog_data.map((post) => post.id) },
                ...all_months(sorted_and_filtered_blog_data),
              ].map((month, index) => (
                <button
                  aria-label={`Filter blogs by ${month.date}`}
                  key={index} // Added a key for each button
                  className={`flex justify-start py-1 px-8 ${
                    selectedMonth === month.date
                      ? "bg-gray-200"
                      : month.posts.length === 0
                      ? "text-gray-300"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => {
                    setSelectedMonth(month.date);
                    setSelectedBlogs(month.posts);
                  }}
                  disabled={month.posts.length === 0}
                >
                  {month.date}
                </button>
              ))}
            </div>
          </div>

          <button
            aria-label={isSidebarOpen ? "Close sidebar" : "Open sidebar"}
            onClick={toggleSidebar}
            onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
            onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
            className={`${handleScaleInlineTailwind300} absolute right-0.5 top-1/2 transform -translate-y-1/2 translate-x-full w-14 border-r border-y border-gray-200 shadow-xl rounded-r-full bg-white p-2 z-0`}
          >
            <RightArrow
              strokeWidth="1.2"
              className={`w-full stroke-castell fill-white transition-transform duration-1000 ${
                isSidebarOpen ? "rotate-180" : "rotate-0"
              }`}
            />
          </button>
        </div>
      )}
    </React.Fragment>
  );
}
