import React from "react";
import { insertTextbeforeUrlExtention } from "../utils/general/text-formatting.js";
import { b_roll_urls } from "../utils/config/image-links.js";
import { res_landowner_image } from "../utils/config/image-resolutions.js";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations.js";
import { ReactComponent as House } from "../utils/svg_components/house.svg";
import { ReactComponent as Office } from "../utils/svg_components/office.svg";
import { ReactComponent as GlobeEuropeAfrica } from "../utils/svg_components/globe-europe-africa.svg";
import HubspotForm from "../components/HubspotForm.jsx";
import { frm_landowner } from "../utils/config/hubspot_forms.js";
import { page_links } from "../utils/config/page-links.js";
import { ImageLoader } from "../components/ImageLoaders.jsx";
import AccreditationsCarousel from "../components/AccreditationsCarousel.jsx";

const icons = [
  {
    Icon: House,
    text: "Residential",
  },
  {
    Icon: GlobeEuropeAfrica,
    text: "Land",
  },
  {
    Icon: Office,
    text: "Commercial",
  },
];

export default function Landowner() {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-full px-[15%] py-12 gap-12 1.5xl:py-16 1.5xl:gap-16 bg-white text-black">
        {/* LANDOWNERS */}
        <div className="flex flex-col 1.5xl:flex-row gap-12 1.5xl:gap-16">
          <ImageLoader
            src={insertTextbeforeUrlExtention(b_roll_urls[17], res_landowner_image)}
            alt="United Kingdom Map"
            containerClass="flex h-64 1.5xl:h-auto w-full rounded-2xl overflow-hidden shadow-2xl border-2 border-castell border-opacity-25"
            imageClass="w-full object-cover"
          />
          <div className="flex flex-col gap-8">
            {/* HASSLE FREE */}
            <div className="flex flex-col gap-4 border border-gray-200 shadow-xl p-6 rounded-xl">
              <div className="w-full text-2xl font-semibold">Hassle Free</div>
              <div className="flex flex-col gap-6">
                <span>
                  If you are selling land then we would be interested to hear from you. We make the buying process as
                  streamlined as possible: no red tape, no need to wait around for agents, and no dealing with
                  time-wasters. When we make an offer, it’s solid.
                </span>
                <span>
                  If you know of land or a derelict property that would suit development in Swansea or South Wales, we
                  would also like you to contact us. A referral fee is available for successful land acquisitions.
                </span>
              </div>
            </div>

            {/* FAIR PRICE */}
            <div className="flex flex-col gap-4 border border-gray-200 shadow-xl p-6 rounded-xl ">
              <div className="w-full text-2xl font-semibold">Fair Price</div>
              <div className="flex flex-col gap-6">
                <span>
                  We believe in transparency and fairness and so we will present our calculations with any offer we
                  make, along with plans and clear reasons behind any price. We also have various options for vendors to
                  maximise their return, and are happy to consider and work with vendors on more bespoke deals.
                </span>
              </div>
            </div>

            {/* CLEAR */}
            <div className="flex flex-col gap-4 border border-gray-200 shadow-xl p-6 rounded-xl ">
              <div className="w-full text-2xl font-semibold">Clear</div>
              <div className="flex flex-col gap-6">
                <span>
                  It is important to us that everyone involved in the deal understands the deal, our terms are made
                  simple and clear so that you know what to expect.
                </span>
              </div>
            </div>

            <div className="flex w-full justify-center">
              <button
                aria_label="Contact us"
                className={`px-12 py-2 hover:bg-castell hover:text-white mt-4 font-semibold whitespace-nowrap text-left border border-castell rounded-sm ${handleScaleInlineTailwind300}`}
                onClick={() => (window.location.href = page_links["contact_us"].link)}
                onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
                onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>

        {/* WHAT WE BUY */}
        <div className="flex flex-col gap-8 border border-gray-200 shadow-xl p-6 lg:p-12 rounded-xl">
          <div className="flex flex-col gap-4">
            <div className="flex w-full justify-center">
              <span className="text-2xl md:text-4xl font-semibold">What we buy</span>
            </div>
            <span className="text-center md:text-lg">
              We are open to buying any kind of site, whether it be greenfield or brownfield, commercial or residential,
              whether there be a building that we need to demolish, or a building that we wish to refurbish. We have
              experience with sites that have existing buildings such as pubs, clubs, and houses.
            </span>
          </div>
          <div className="flex w-full justify-center">
            <div className="flex flex-col sm:flex-row sm:justify-between sm:w-[75%] gap-8 sm:gap-0">
              {icons.map((icon, index) => (
                <div key={index} className="flex flex-col items-center gap-2">
                  <icon.Icon strokeWidth="0.9" className={`w-12 lg:w-24 stroke-castell fill-white`} />
                  <span className="text-castell font-semibold text-sm lg:text-xl">{icon.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <AccreditationsCarousel />

        {/* LANDOWNER FORM */}
        <div className="flex flex-col w-full gap-8 border border-gray-200 shadow-xl p-6 lg:p-12 rounded-xl">
          <div className="flex flex-col w-full items-center gap-2 text-center">
            <span className="text-2xl md:text-4xl font-semibold">Are you aware of a suitable land opportunity?</span>
            <span className="text-2xl md:text-4xl font-semibold">If so, we'd love to hear from you.</span>
          </div>
          <HubspotForm region={frm_landowner.region} portalId={frm_landowner.portalId} formId={frm_landowner.formId} />
        </div>
      </div>
    </div>
  );
}
