const our_services = [
  {
    id: "service1",
    title: "Off the shelf, package and golden brick",
    description: [
      "We understand the standards and requirements of social housing. We can fund, acquire, and deliver completed and signed off schemes for our clients as a turnkey, minimising risk for our clients.",
      "We can also offer package deals for those clients that want more control over the development, and a more tailored product.",
    ],
  },
  {
    id: "service3",
    title: "Maintenance",
    description: [
      "As standard, our completed developments include a defects maintenance period. We can also look to assist social landlords with their ongoing maintenance.",
    ],
  },
  {
    id: "service2",
    title: "Modern construction",
    description: [
      "We strive to implement innovative and energy efficient methods of construction across all of our sites, including Passivhaus, offsite construction, and renewable energy.",
      "We are continuously looking to implement new Modern Methods of Construction into our developments, including in refurbished properties.",
    ],
  },
];

export { our_services };
