import { image_url_prefix } from "./config.js";

const office_data = [
  {
    id: "hq",
    office_name: "Castell HQ",
    type: "Head Office",
    address: {
      post_code: "SA7 0AP",
      number: "Unit 5",
      street_number: "Dyffryn Court, Riverside Business Park",
      district: "Swansea Vale",
      county: "Swansea",
      country: "Wales",
      longitude: "51.669634425400375",
      latitude: "-3.9149328375499284",
    },
    main_photo_url: `${image_url_prefix}2-hq-photo-1.jpg`,
    main_video_url: "",
    photo_urls: [`${image_url_prefix}2-hq-photo-1.jpg`],
    video_urls: [],
    description: "",
    google_maps_url: "https://www.google.com.ua/maps/@51.6694225,-3.9150588,154m/data=!3m1!1e3?entry=ttu",
  },
];

export { office_data };
