const about_us = {
  id: "footer-block",
  text: "We deliver high quality, energy sustainable, affordable, social and disability adapted homes for those truly in need.",
};

const our_core_values = [
  "Honesty - Building trust through transparent and truthful practices in every step of the delivery process.",
  "Collaboration - Working hand in hand with local communities and partners to construct sustainable and inclusive housing projects.",
  "Quality - Committed to delivery high-quality social housing projects that meet the needs of our communities and stand the test of time.",
  "Reliability - Dependable and timely delivery of housing projects that exceed expectations, built on a foundation of reliability.",
];

const our_mission = [
  "Providing vendors with a fair value and smooth transaction",
  "Developing on brownfield as much as possible",
  "Raising standards by providing quality education",
  "Working towards zero carbon by embracing innovation and utilising MMC across our development sites",
  "Rectifying maintenance promptly and reducing further maintenance by building with quality",
  "Contributing to society with well paid and safe jobs",
];

const our_culture = [
  "Driven - Relentlessly pursuing success with a tenacious work ethic and unwavering determination.",
  "Humble - Enhancing the team through a grounded attitude, refreshing sense of modesty and teamwork.",
  "Smart - Expertly navigating interpersonal dynamics and fostering collaborative relationships.",
];

export { about_us, our_core_values, our_mission, our_culture };
