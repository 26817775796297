import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { google_analytics_tracking_id, items_tracked, ga4_default_tracking } from "./ga-tracking.js";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations.js";

// Define the Consent component
const Ga4CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("ga4cookieConsent");
    if (!consent) {
      setShowConsent(true); // Show consent form if no consent was previously given
    } else if (consent === "true") {
      initializeGA(); // If consent was given earlier, initialize GA4
    }
  }, []);

  // Function to handle user consent
  const handleAccept = () => {
    localStorage.setItem("ga4cookieConsent", "true");
    setShowConsent(false);
    initializeGA(); // Initialize GA4 after consent
  };

  const handleDecline = () => {
    localStorage.setItem("ga4cookieConsent", "false");
    setShowConsent(false); // Just hide the consent banner
  };

  // Function to initialize Google Analytics 4
  const initializeGA = () => {
    ReactGA.initialize(google_analytics_tracking_id, {
      gaOptions: {
        anonymizeIp: true, // Ensures that IPs are anonymized (this is automatic in GA4)
      },
    });
  };

  if (!showConsent) return null; // Don't show the component if consent is already given/declined

  return (
    <div className="fixed top-0 left-0 w-full h-full  bg-castell bg-opacity-40 z-50 backdrop-blur-extra-sm">
      <div
        className={`flex w-[90%] md:w-[80%] lg:w-[70%] text-xs md:text-sm lg:text-base max-h-[90%] bg-white text-black border-2 border-castell font-semibold shadow-2xl fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  rounded-2xl p-8 ${handleScaleInlineTailwind300}`}
        onMouseEnter={(e) => e.currentTarget.classList.add("scale-105")}
        onMouseLeave={(e) => e.currentTarget.classList.remove("scale-105")}
      >
        <div className="flex flex-col overflow-y-auto gap-8 md:gap-12">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-2">
              <div className="text-2xl md:text-3xl lg:text-4xl">Cookies Consent</div>
              <div className="text-base md:text-lg lg:text-xl pr-6">
                We <span className="font-bold text-syracuseorange-400">do not</span> track any{" "}
                <span className="font-bold text-syracuseorange-400">Personally Identifiable Information (PII)</span> All
                data collected is <span className="font-bold text-syracuseorange-400">anonymised</span> and{" "}
                <span className="font-bold text-syracuseorange-400">aggregated</span> via{" "}
                <span className="font-bold text-syracuseorange-400">Google Analytics</span>. Please see below a list of
                what we track.
              </div>
              <div className="flex flex-col text-syracuseorange-400">
                {items_tracked.map((item, index) => (
                  <div className="flex flex-row">
                    <div className="min-w-6">{`${String.fromCharCode(160, 160)}•`}</div>
                    <div className="pr-6">{`${item}`}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-base md:text-lg lg:text-xl pr-6">
                Google Analytics also tracks the following by default{" "}
                <span className="font-bold text-syracuseorange-400">(Anonymised)</span>
              </div>
              <div className="flex flex-col text-syracuseorange-400">
                {ga4_default_tracking.map((item, index) => (
                  <div className="flex flex-row">
                    <div className="min-w-6">{`${String.fromCharCode(160, 160)}•`}</div>
                    <div className="pr-6">{`${item}`}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-8 justify-center w-full text-castell pb-4">
            <button
              aria-label="Accept cookies"
              className={`px-8 md:px-12 lg:px-16 py-2 text-base md:text-lg lg:text-xl border-2 border-castell rounded-md shadow-lg hover:bg-castell hover:text-white ${handleScaleInlineTailwind300}`}
              onClick={handleAccept}
              onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
              onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
            >
              Accept
            </button>
            <button
              aria-label="Decline cookies"
              className={`px-8 md:px-12 lg:px-16 py-2 text-base md:text-lg lg:text-xl border-2 border-castell rounded-md shadow-lg hover:bg-castell hover:text-white ${handleScaleInlineTailwind300}`}
              onClick={handleDecline}
              onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
              onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ga4CookieConsent;
