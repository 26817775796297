// Imports
import { createContext, useState } from "react";
// import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { vacancies_data } from "../utils/config/vacancies";
import { department_data } from "../utils/config/staff";

// User Context Provider
export const UserContext = createContext({});
export function UserContextProvider({ children }) {
  const location = useLocation();
  // const initialDarkMode = Cookies.get("darkMode") === "true";
  // const [darkMode, setDarkMode] = useState(initialDarkMode);

  // Dark Mode
  // useEffect(() => {
  //   Cookies.set("darkMode", darkMode);
  // }, [darkMode]);

  // If not Loading then return UserContext

  const [selectedBlogs, setSelectedBlogs] = useState([]);
  const [selectedVacancyDepartment, setSelectedVacancyDepartment] = useState({ id: "all", name: "All Vacancies" });
  const [vacancyDepartments, setVacancyDepartments] = useState([
    { id: "all", name: "All Vacancies" },
    ...Object.values(department_data).filter((department) =>
      Object.values(vacancies_data)
        .map((vacancy) => vacancy.department.id)
        .includes(department.id)
    ),
  ]);

  return (
    <UserContext.Provider
      value={{
        // darkMode,
        // setDarkMode,
        location,
        selectedBlogs,
        setSelectedBlogs,
        vacancyDepartments,
        setVacancyDepartments,
        selectedVacancyDepartment,
        setSelectedVacancyDepartment,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
