import React, { useEffect, useRef } from "react"; // useState
import { insertTextbeforeUrlExtention } from "../utils/general/text-formatting.js";
import { res_accreditation_carousel } from "../utils/config/image-resolutions.js";
import { accreditations } from "../utils/config/accreditations.js";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations.js";
import { ImageLoader } from "./ImageLoaders.jsx";

const accreditation_scroll = (num) => {
  return Object.values(accreditations).map((accreditation, index) => (
    <button
      aria-label={`Open ${accreditation.name}'s website`}
      key={`btn${index}${num}`}
      onClick={() => window.open(accreditation.link, "_blank")}
      className={`flex-none h-full transition-transform duration-300 py-4 ${handleScaleInlineTailwind300}`}
      onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
      onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
    >
      <ImageLoader
        src={insertTextbeforeUrlExtention(accreditation.img.url, res_accreditation_carousel)}
        alt={accreditation.img.alt}
        containerClass="h-full w-full"
        imageClass="h-full w-auto object-cover py-4 px-8 overflow-hidden"
      />
    </button>
  ));
};

export default function AccreditationsCarousel() {
  const carouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;

    const handleScroll = () => {
      if (carousel.scrollLeft >= carousel.scrollWidth / 2) {
        carousel.scrollLeft = 0;
      } else {
        carousel.scrollLeft += 1;
      }
    };

    const intervalId = setInterval(handleScroll, 20); // Adjust the speed as needed

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="w-full h-24 sm:h-32 relative bg-white">
      <div ref={carouselRef} className="absolute overflow-hidden w-full h-full flex flex-nowrap">
        <div className="flex h-full items-center gap-8">
          {accreditation_scroll(1)}
          {accreditation_scroll(2)}
          {accreditation_scroll(3)}
        </div>
      </div>

      <div className={`absolute top-1/2 transform -translate-y-1/2 left-0 h-full flex flex-row`}>
        <div className="flex h-full pl-8 pr-4 bg-white items-center"></div>
        <div className="w-32 h-full bg-gradient-to-r from-white to-transparent"></div>
      </div>

      <div className={`absolute top-1/2 transform -translate-y-1/2 right-0 h-full flex flex-row`}>
        <div className="w-32 h-full bg-gradient-to-l from-white to-transparent"></div>
        <div className="flex h-full pr-8 pl-4 bg-white items-center"></div>
      </div>
    </div>
  );
}
