import { UserContext } from "../context_providers/UserContext.jsx";
import { useContext } from "react";
import { Link } from "react-router-dom";
import {
  footer_top_about_us,
  footer_top_contacts,
  footer_top_links,
  footer_top_socials,
} from "../utils/config/config.js";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations.js";
import DynamicLink from "./DynamicLink.jsx";
import ReactGA from "react-ga4";
import { locations } from "../utils/config/locations.js";
import { miscellanious } from "../utils/config/miscellanious.js";

function FooterTop() {
  const { location } = useContext(UserContext);

  return (
    <div className="text-castell bg-white">
      <div className="relative top-0 left-0 w-full h-2 bg-gradient-to-t from-gray-200 to-transparent" />
      <div className="flex flex-col md:flex-row w-full bg-gray-100 md:justify-between px-[10%] py-8 gap-8">
        <div className="flex flex-col md:w-[25%] gap-4">
          <h1 className="text-3xl font-semibold border-b-2 border-castell pb-1">About Us</h1>
          <p className=" font-semibold">{footer_top_about_us.text}</p>
        </div>
        <div className="flex flex-col md:w-[25%] gap-4">
          <h1 className="text-3xl font-semibold border-b-2 border-castell pb-1">Quick Links</h1>
          <div className="flex flex-col gap-2 font-semibold">
            {Object.values(footer_top_links).map((link) => {
              const isActive =
                (link.link === "/" && location.pathname === "/") ||
                (link.link !== "/" && location.pathname.startsWith(link.link));

              return (
                <Link
                  aria_label={`Navigate to ${link.text} page`}
                  key={link.id}
                  to={link.link}
                  onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
                  onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
                  className={`${handleScaleInlineTailwind300} ${isActive && "text-orangemain"}`}
                >
                  <DynamicLink link={link} bullet={true} />
                </Link>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col md:w-[25%] gap-4">
          <h1 className="text-3xl font-semibold border-b-2 border-castell pb-1">Contact Us</h1>
          <div className="flex flex-col gap-2 font-semibold">
            {footer_top_contacts.map((item) => (
              <a
                aria-label={`Contact Castell on ${item.text}`}
                key={item.id}
                href={item.link}
                onMouseEnter={(e) => e.currentTarget.classList.add("scale-105")}
                onMouseLeave={(e) => e.currentTarget.classList.remove("scale-105")}
                className={`flex gap-2 ${handleScaleInlineTailwind300}`}
                onClick={() =>
                  ReactGA.event({
                    category: item.ga4_event.category,
                    action: item.ga4_event.action,
                    label: item.ga4_event.label,
                  })
                }
              >
                <item.icon strokeWidth="1.2" className="w-4 stroke-white fill-castell" />
                {item.text}
              </a>
            ))}
          </div>
          <div className="flex flex-row gap-4 ">
            {footer_top_socials.map((social) => (
              <a
                aria-label={`Visit Castell on ${social.id}`}
                key={social.id}
                href={social.link}
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={(e) => e.currentTarget.classList.add("scale-125")}
                onMouseLeave={(e) => e.currentTarget.classList.remove("scale-125")}
                className={`${handleScaleInlineTailwind300}`}
                onClick={() =>
                  ReactGA.event({
                    category: social.ga4_event.category,
                    action: social.ga4_event.action,
                    label: social.ga4_event.label,
                  })
                }
              >
                <social.icon strokeWidth="0.9" className={`w-6 stroke-white fill-castell`} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function FooterBottom() {
  const location = Object.values(locations)[0];
  const copyright = miscellanious[0];

  return (
    <div className="flex flex-col font-semibold w-full text-white bg-castell items-center justify-center px-[5%] text-center py-8 gap-2">
      <a
        aria_label={`Castell Main Office link`}
        href={location.link || "#"}
        onMouseEnter={(e) => {
          if (location.id === "main-office") {
            e.currentTarget.classList.add("scale-105");
          }
        }}
        onMouseLeave={(e) => {
          if (location.id === "main-office") {
            e.currentTarget.classList.remove("scale-105");
          }
        }}
        className={`flex gap-2 ${location.id === "main-office" && handleScaleInlineTailwind300}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="flex gap-2">
          <div>{location.icon && <location.icon strokeWidth="1.2" className={`w-6 stroke-castell fill-white`} />}</div>
          <div>{location.text}</div>
        </div>
      </a>
      <div>{copyright.text}</div>
    </div>
  );
}

export { FooterTop, FooterBottom };
