import { useEffect, useState } from "react";
import { testimonials } from "../utils/config/testimonials";
import { ReactComponent as ArrowLeft } from "../utils/svg_components/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../utils/svg_components/arrow-right.svg";
import { insertTextbeforeUrlExtention } from "../utils/general/text-formatting.js";
import { res_testimonial_image } from "../utils/config/image-resolutions";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations";
import { ImageLoader } from "./ImageLoaders.jsx";

const testimonialsArray = Object.values(testimonials);

export default function Testimonials() {
  const [selectedTestimonial, setSelectedTestimonial] = useState(0);

  useEffect(() => {
    const interval = setInterval(async () => {
      await handleNextClick();
    }, 5000);

    return () => clearInterval(interval);
  }, [selectedTestimonial]); // eslint-disable-line

  const handleNextClick = async () => {
    setSelectedTestimonial((prevIndex) => (prevIndex + 1) % testimonialsArray.length);
  };

  const handlePrevClick = async () => {
    setSelectedTestimonial((prevIndex) => (prevIndex - 1 + testimonialsArray.length) % testimonialsArray.length);
  };

  return (
    <div className="relative w-full h-96 lg:h-48 2xl:h-64 text-white bg-smokyblack flex justify-center items-center">
      <div className="relative flex flex-col lg:flex-row w-[60%] h-full z-10 py-6 lg:py-4 gap-6 lg:gap-12 items-center">
        <div className="flex">
          <ImageLoader
            src={insertTextbeforeUrlExtention(testimonialsArray[selectedTestimonial].image_url, res_testimonial_image)}
            alt={testimonialsArray[selectedTestimonial].name}
            containerClass="relative w-32 h-32 2xl:w-48 2xl:h-48 rounded-full overflow-hidden border-2 border-white border-opacity-20"
            imageClass="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col h-full justify-between">
          <div className="flex flex-col gap-4 lg:h-full justify-center">
            <div className="text-base 2xl:text-3xl">{testimonialsArray[selectedTestimonial].testimonial}</div>
            <div className="text-xs 2xl:text-xl">{`${testimonialsArray[selectedTestimonial].name} - ${testimonialsArray[selectedTestimonial].job_title} | ${testimonialsArray[selectedTestimonial].company}`}</div>
          </div>
          <div className="flex flex-row w-full justify-center py-2 items-center">
            {testimonialsArray.map((nullvalue, index) => (
              <div key={`testimonial${index}`} className="flex w-8 lg:w-12 justify-center">
                <button
                  aria-label={`View testimonial from ${selectedTestimonial.name}, ${selectedTestimonial.job_title} at ${selectedTestimonial.company}`}
                  onClick={() => setSelectedTestimonial(index)}
                  className={`rounded-full ${
                    selectedTestimonial === index
                      ? "w-3 h-3 2xl:w-4 2xl:h-4 border-2 border-white bg-transparent"
                      : "w-2 h-2 2xl:w-3 2xl:h-3 bg-white"
                  } ${handleScaleInlineTailwind300}`}
                  onMouseEnter={(e) => e.currentTarget.classList.add("scale-150")}
                  onMouseLeave={(e) => e.currentTarget.classList.remove("scale-150")}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <button
        aria-label="Previous Testimonial"
        onClick={handlePrevClick}
        onMouseEnter={(e) => e.currentTarget.classList.add("scale-125")}
        onMouseLeave={(e) => e.currentTarget.classList.remove("scale-125")}
        className={`absolute left-0 top-1/2 transform -translate-y-1/2 focus:outline-none z-30 w-8 lg:w-12 ml-6 lg:ml-12 ${handleScaleInlineTailwind300}`}
      >
        <ArrowLeft strokeWidth="1.5" className="h-full stroke-white" />
      </button>
      <button
        aria-label="Next Testimonial"
        onClick={handleNextClick}
        onMouseEnter={(e) => e.currentTarget.classList.add("scale-125")}
        onMouseLeave={(e) => e.currentTarget.classList.remove("scale-125")}
        className={`absolute right-0 top-1/2 transform -translate-y-1/2 focus:outline-none z-30 w-8 lg:w-12 mr-6 lg:mr-12 ${handleScaleInlineTailwind300}`}
      >
        <ArrowRight strokeWidth="1.5" className="h-full stroke-white" />
      </button>
    </div>
  );
}
