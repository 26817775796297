const convertExcelDateToJsDate = (excelDate) => {
  const date = new Date((excelDate - (25567 + 2)) * 86400 * 1000);
  return date;
};

const convertJsDateToExcelDate = (jsDate) => {
  const excelDate = jsDate.getTime() / (86400 * 1000) + 25567 + 2;
  return excelDate;
};

const convertMmmYySpaceToDate = (mmmSpaceYy) => {
  const mmmYy = mmmSpaceYy.replace(/\s+/g, "");
  const month = mmmYy.slice(0, 3);
  const year = mmmYy.slice(3, 5);
  const date = new Date(`20${year}-${month}-01`);
  return date;
};

const convertDateToMmmYySpace = (date) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear().toString().substr(-2);
  return `${month} ${year}`;
};

const convertDateToYyyyMmDd = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

const convertYyyymmddToWordyDate = (date) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const addOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return day + "th";
    switch (day % 10) {
      case 1:
        return day + "st";
      case 2:
        return day + "nd";
      case 3:
        return day + "rd";
      default:
        return day + "th";
    }
  };

  const [year, month, day] = date.split("-").map(Number);
  const monthName = months[month - 1];
  const dayWithSuffix = addOrdinalSuffix(day);

  return `${monthName} ${dayWithSuffix}, ${year}`;
};

const convertYyyymmddToMmmyy = (date) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const [year, month] = date.split("-").map(Number);
  const monthName = months[month - 1];
  return `${monthName} - ${year}`;
};

export {
  convertExcelDateToJsDate,
  convertJsDateToExcelDate,
  convertMmmYySpaceToDate,
  convertDateToMmmYySpace,
  convertDateToYyyyMmDd,
  convertYyyymmddToWordyDate,
  convertYyyymmddToMmmyy,
};
