import L from "leaflet";
import { insertTextbeforeUrlExtention } from "../general/text-formatting.js";
import { Marker, Popup, useMap } from "react-leaflet";
import { useEffect, useRef } from "react";
import { castellRegex } from "../general/regex.js";
import { page_links } from "./page-links.js";
import { ButtonWithLoadingBackground } from "../../components/ImageLoaders.jsx";

const map_config = {
  // center: [55.75, -4],
  // zoom: 5,
  center: [51.6, -3.6],
  zoom: 9,
  tileLayerUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  tileLayerAttribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a>",
  maxBounds: [
    [49.837982, -10.854492], // Southwest coordinates of the UK
    [59.445075, 2.098694], // Northeast coordinates of the UK
  ],
  minZoom: 5,
  maxBoundsViscosity: 1.0, // Fully restricts panning outside the bounds
};

const project_map_zoom = 15;

const google_map_link = (longitude, latitude, height) =>
  `https://www.google.com.ua/maps/@${longitude},${latitude},${height}m`;

const what3words_link = (w3w) => `https://what3words.com/${w3w}`;

const icon_styles = {
  castell: "bg-castell border-white",
  default: "bg-white border-black",
  archive: "bg-gray-500 border-white",
  completed: "bg-green-500 border-white",
  construction: "bg-blue-500 border-white",
  planning: "bg-yellow-500 border-white",
  hots_agreed: "bg-pink-500 border-white",
  pre_app: "bg-purple-500 border-white",
  legals_draft_contract: "bg-pink-500 border-white",
  offer_accepted: "bg-indigo-500 border-white",
};

const icon_template = (style, icon_size = [15, 15]) =>
  L.divIcon({
    className: `rounded-full border ${style}`,
    iconSize: icon_size,
    iconAnchor: [8, 4],
  });

const popup_template = (data_object, template_image, image_res, photo = "cgi", on_click_link = "") => {
  return (
    <div className="w-48 rounded-md overflow-hidden">
      <div className="flex w-full border-x border-t border-black rounded-t-md justify-center items-center pt-2 pb-1 font-semibold bg-gray-100">
        {data_object.given_name || data_object.project_name || data_object.office_name}
      </div>
      <ButtonWithLoadingBackground
        aria_label={`View ${data_object.given_name || data_object.project_name || data_object.office_name}`}
        key={data_object.id}
        onClick={() => {
          if (on_click_link) {
            window.open(on_click_link, "_blank");
          } else if (castellRegex.projectCode.test(data_object.id)) {
            window.location.href = `${page_links["project"].link}/${data_object.id}`;
          }
        }}
        className="w-full h-20 bg-cover bg-center border border-black rounded-b-md"
        imageUrl={insertTextbeforeUrlExtention(
          photo === "photo"
            ? data_object.main_photo_url !== ""
              ? data_object.main_photo_url
              : template_image
            : data_object.main_cgi_url !== ""
            ? data_object.main_cgi_url
            : template_image,
          image_res
        )}
      ></ButtonWithLoadingBackground>
    </div>
  );
};

const AutoOpenMarker = ({ data_object, icon, popupContent }) => {
  const map = useMap();
  const markerRef = useRef();

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.openPopup();
    }
  }, [map]);

  return (
    <Marker position={[data_object.address.longitude, data_object.address.latitude]} icon={icon} ref={markerRef}>
      <Popup>{popupContent}</Popup>
    </Marker>
  );
};

export {
  map_config,
  icon_styles,
  icon_template,
  project_map_zoom,
  popup_template,
  google_map_link,
  what3words_link,
  AutoOpenMarker,
};
