import { useCallback, useRef, useState } from "react";
import { projects_data } from "../utils/config/projects.js";
import { insertTextbeforeUrlExtention } from "../utils/general/text-formatting.js";
import { res_our_portfolio_projects, res_map_popup_image } from "../utils/config/image-resolutions.js";
import { handleMouseMove, handleMouseLeave } from "../utils/animations/tilt-animation-functions.js";
import { template_image_light, template_image_dark } from "../utils/config/image-links.js";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations.js";
import { ReactComponent as Hamburger } from "../utils/svg_components/hamburger.svg";
import { page_links } from "../utils/config/page-links.js";
import { ButtonWithLoadingBackground } from "../components/ImageLoaders.jsx";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { map_config, icon_styles, icon_template, popup_template } from "../utils/config/map-data.js";

export default function Projects() {
  const buttonRefs = useRef([]);
  const [currentStage, setCurrentStage] = useState("All Projects");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const stages = ["All Projects", ...new Set(Object.values(projects_data).map((project) => project.stage))];
  const [projectHovered, setProjectHovered] = useState(null);

  const throttledMouseMove = useRef(handleMouseMove(buttonRefs));
  // eslint-disable-next-line
  const handleMouseMoveCallback = useCallback(throttledMouseMove.current, []);
  // eslint-disable-next-line
  const handleMouseLeaveCallback = useCallback(handleMouseLeave(buttonRefs, throttledMouseMove.current), []);

  const locations = Object.values(projects_data)
    .filter(
      (project) =>
        project.address.latitude &&
        project.address.longitude &&
        project.stage_id &&
        (project.stage === currentStage || currentStage === "All Projects")
    )
    .map((project) => ({
      id: project.id,
      project_name: project.given_name || project.project_name,
      position: [project.address.longitude, project.address.latitude],
      icon: icon_styles[project.stage_id],
      main_cgi_url: project.main_cgi_url,
    }));

  return (
    <div className="flex flex-col w-full bg-white px-[10%] border-t border-black gap-8 py-8">
      <div className="flex w-full relative md:hidden">
        <button
          aria-label="Open project stage filter"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="flex justify-center text-castell font-semibold text-lg w-full py-4 items-center"
        >
          <Hamburger strokeWidth="1.2" className="w-10 stroke-castell fill-white" />
          <div className="flex ml-4 text-xl">Filter by Project Stage</div>
        </button>
        {isDropdownOpen && (
          <div className="absolute top-full left-0 w-full bg-white border border-gray-500 rounded-md z-40">
            <div className="flex flex-col text-lg text-castell font-semibold p-1">
              {stages.map((stage, index) => (
                <button
                  aria-label={`Filter projects by ${stage}`}
                  key={index}
                  className={`rounded-md px-8 ${handleScaleInlineTailwind300} ${
                    stage === currentStage ? "bg-castell text-white" : "hover:bg-castellpastel-100"
                  }`}
                  onClick={() => setCurrentStage(stage)}
                >
                  {stage}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="hidden md:flex flex-wrap w-full justify-center rounded-md gap-4 font-semibold text-lg">
        {stages.map((stage, index) => (
          <button
            aria-label={`Filter projects by ${stage}`}
            key={index}
            onMouseEnter={(e) => e.currentTarget.classList.add("scale-105")}
            onMouseLeave={(e) => e.currentTarget.classList.remove("scale-105")}
            className={`py-2 rounded-md px-8 border-2 border-castell ${handleScaleInlineTailwind300} ${
              stage === currentStage ? "bg-castell text-white" : "hover:bg-castellpastel-100"
            }`}
            onClick={() => setCurrentStage(stage)}
          >
            {stage}
          </button>
        ))}
      </div>

      <div className="w-full h-64 md:h-128 bg-white z-30">
        <MapContainer
          center={map_config.center}
          zoom={map_config.zoom}
          maxBounds={map_config.maxBounds}
          maxBoundsViscosity={map_config.maxBoundsViscosity}
          minZoom={map_config.minZoom}
          className="h-full w-full rounded-lg overflow-hidden border border-black"
        >
          <TileLayer url={map_config.tileLayerUrl} attribution={map_config.tileLayerAttribution} />
          {locations.map((location) => (
            <Marker
              key={location.id}
              position={location.position}
              icon={icon_template(location.icon, location.id === projectHovered ? [30, 30] : [15, 15])}
            >
              <Popup>{popup_template(location, template_image_dark, res_map_popup_image)}</Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>

      <div className="w-full bg-white grid grid-cols-2 md:grid-cols-4 lg:gap-4 gap-2 text-white font-semibold text-sm sm:text-base md:text-lg mb-12">
        {Object.values(projects_data)
          .filter((project) => project.stage === currentStage || currentStage === "All Projects")
          .map((project, index) => (
            <div
              key={index}
              ref={(el) => (buttonRefs.current[index] = el)}
              className="transition-transform duration-150"
              onMouseMove={(e) => handleMouseMoveCallback(e, index)}
              onMouseLeave={() => {
                handleMouseLeaveCallback(index);
                setProjectHovered(null);
              }}
              onMouseEnter={() => setProjectHovered(project.id)}
            >
              <ButtonWithLoadingBackground
                aria_label={`View ${project.given_name || project.project_name}`}
                onClick={() => {
                  window.location.href = `${page_links["project"].link}/${project.id}`;
                }}
                className="w-full h-16 md:h-32 bg-cover bg-center rounded-md overflow-hidden"
                imageUrl={insertTextbeforeUrlExtention(
                  project.main_cgi_url !== "" ? project.main_cgi_url : template_image_light,
                  res_our_portfolio_projects
                )}
              >
                <div className="absolute inset-0 bg-castell bg-opacity-35 backdrop-blur-extra-sm"></div>
                <span className="relative">{project.given_name || project.project_name}</span>
              </ButtonWithLoadingBackground>
            </div>
          ))}
      </div>
    </div>
  );
}
