import React, { useCallback, useRef } from "react";
import { free_resources_data } from "../utils/config/free_resources";
import { insertTextbeforeUrlExtention } from "../utils/general/text-formatting";
import { res_free_resources } from "../utils/config/image-resolutions";
import { handleMouseMove, handleMouseLeave } from "../utils/animations/tilt-animation-functions.js";
import { ImageLoader } from "../components/ImageLoaders.jsx";
import { template_image_light } from "../utils/config/image-links.js";

export default function FreeResources() {
  const buttonRefs = useRef([]);

  const throttledMouseMove = useRef(handleMouseMove(buttonRefs));
  // eslint-disable-next-line
  const handleMouseMoveCallback = useCallback(throttledMouseMove.current, []);
  // eslint-disable-next-line
  const handleMouseLeaveCallback = useCallback(handleMouseLeave(buttonRefs, throttledMouseMove.current), []);

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 w-full bg-white px-[15%] xl:px-[20%] py-8 md:py-12 xl:py-16 gap-4 lg:gap-6 xl:gap-8">
      {Object.values(free_resources_data)
        .filter((resource) => resource.active === true)
        .map((resource, index) => (
          <button
            aria-label={`Download ${resource.title}`}
            key={index}
            ref={(el) => (buttonRefs.current[index] = el)}
            onClick={() => window.open(resource.hubspot_form_link, "_blank")}
            className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200 transition-transform duration-150"
            onMouseMove={(e) => handleMouseMoveCallback(e, index)}
            onMouseLeave={() => handleMouseLeaveCallback(index)}
          >
            <ImageLoader
              src={
                resource.image.image_url !== ""
                  ? resource.image.image_url
                  : insertTextbeforeUrlExtention(template_image_light, res_free_resources)
              }
              alt={resource.image.image_alt}
              containerClass="flex w-full h-48 md:h-52 xl:h-64 "
              imageClass={`w-full h-48 md:h-52 xl:h-64 object-cover`}
            />
            <div className="text-sm md:text-base xl:text-xl font-semibold px-2 py-1">{resource.title}</div>
          </button>
        ))}
    </div>
  );
}
