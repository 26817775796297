import { roundToDp } from "./numbers.js";

const calculateTimeBetweenDates = (startDate, endDate, period) => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  const differenceInTime = endDateObj - startDateObj;

  const normalizedPeriod = period.toLowerCase();
  const day = 1000 * 3600 * 24;

  switch (normalizedPeriod) {
    case "seconds":
      return differenceInTime / 1000;
    case "minutes":
      return differenceInTime / 60000;
    case "hours":
      return differenceInTime / 3600000;
    case "days":
      return differenceInTime / day;
    case "weeks":
      return differenceInTime / (day * 7);
    case "months":
      return differenceInTime / (day * 30);
    case "years":
      return differenceInTime / (day * 365);
    default:
      return differenceInTime;
  }
};

const convertJsonToSize = (json, type) => {
  const jsonStr = JSON.stringify(json);
  const sizeInBytes = jsonStr.length;
  const normalizedType = type.toLowerCase();
  switch (normalizedType) {
    case "kb":
      return `Data size is ${(sizeInBytes / 1024).toFixed(3)} kb.`;
    case "mb":
      return `Data size is ${(sizeInBytes / 1024 ** 2).toFixed(3)} mb.`;
    case "gb":
      return `Data size is ${(sizeInBytes / 1024 ** 3).toFixed(3)} gb.`;
    default:
      return `Data size is ${sizeInBytes.toFixed(0)} bytes.`;
  }
};

const functionDuration = async (fn, ...args) => {
  const start = Date.now();
  const result = await fn(...args); // Execute the function with arguments
  const end = Date.now();
  console.log(
    `Start Time: ${new Date(start).toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })}  ---  Duration: ${roundToDp((end - start) / 1000, 3)
      .toString()
      .padStart(7, " ")} seconds  ---  ${fn.name} `
  );
  return result;
};

const logEveryFiveSeconds = async (stopSignal, step = 1000) => {
  let time = 0;
  const start = Date.now();
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (stopSignal.done) {
        console.log("Stopping logging...");
        console.log(`Total time elapsed: ${roundToDp((Date.now() - start) / 1000, 3).toString()} seconds.`);
        clearInterval(interval);
        resolve();
      } else {
        time += step;
        console.log(time / 1000, `seconds have passed.`);
      }
    }, step);
  });
};

const isDate = (value) => {
  if (value === null) {
    return false;
  }
  if (value instanceof Date) {
    return !isNaN(value.getTime());
  } else {
    const date = new Date(value);
    return !isNaN(date.getTime());
  }
};

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const generateStepArray = (start, end, steps) => {
  const step = (end - start) / (steps + 1);
  const stepArray = [];
  for (let i = 0; i < steps; i++) {
    stepArray.push(start + i * step);
  }
  return stepArray;
};

const getRandomElements = (arr, count) => {
  const shuffled = arr.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

export {
  calculateTimeBetweenDates,
  convertJsonToSize,
  functionDuration,
  logEveryFiveSeconds,
  isDate,
  shuffleArray,
  generateStepArray,
  getRandomElements,
};
